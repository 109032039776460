import { ThunkDispatch } from 'redux-thunk';
import actionTypes from 'store/action-types';
import { createAction } from 'shared/util/utility';
import { Action } from 'shared/interface';
import HttpService from 'shared/services/http.service';
import { API_CONFIGURATION } from 'shared/constants/constants';
import { TestCenterParamSchema, CenterFetchParameters } from './centers.interface';

const fetchTestCenters = (parameters: CenterFetchParameters) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(createAction(actionTypes.FETCH_TEST_CENTERS_INIT, parameters));
		HttpService.get(API_CONFIGURATION.path.centers, '', parameters).then(data => {
			dispatch(createAction(actionTypes.FETCH_TEST_CENTERS_SUCCESS, data));
		}).catch((error) => dispatch(createAction(actionTypes.FETCH_TEST_CENTERS_FAIL, { message: error.message })));
	};
}

const fetchActualCenters = (parameters: CenterFetchParameters) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(createAction(actionTypes.FETCH_ACTUAL_CENTERS_INIT, parameters));
		HttpService.get(API_CONFIGURATION.path.centers, 'actual', parameters).then(data => {
			dispatch(createAction(actionTypes.FETCH_ACTUAL_CENTERS_SUCCESS, data));
		}).catch((error) => dispatch(createAction(actionTypes.FETCH_ACTUAL_CENTERS_FAIL, { message: error.message })));
	};
}

const addTestCenter = (data: TestCenterParamSchema) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(createAction(actionTypes.ADD_TEST_CENTER_INIT));
		HttpService.post(API_CONFIGURATION.path.centers, '', data).then(data => {
			dispatch(createAction(actionTypes.ADD_TEST_CENTER_SUCCESS, {
				center: data.center,
				message: 'Center has been added'
			}));
			dispatch(createAction(actionTypes.CLOSE_MODAL));
		}).catch((error) => dispatch(createAction(actionTypes.ADD_TEST_CENTER_FAIL, { message: error.message })));
	};
}

const editTestCenter = (id: string, data: TestCenterParamSchema) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(createAction(actionTypes.EDIT_TEST_CENTER_INIT));
		HttpService.put(API_CONFIGURATION.path.centers, id, data).then(() => {
			dispatch(createAction(actionTypes.EDIT_TEST_CENTER_SUCCESS, {
				center: data,
				id,
				message: 'Center has been updated'
			}));
			dispatch(createAction(actionTypes.CLOSE_MODAL));
		}).catch((error) => dispatch(createAction(actionTypes.EDIT_TEST_CENTER_FAIL, { message: error.message })));
	};
}

const deleteTestCenter = (id: string) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(createAction(actionTypes.DELETE_CENTER_INIT));
		HttpService.deleteRequest(API_CONFIGURATION.path.centers, id).then(() => {
			dispatch(createAction(actionTypes.DELETE_CENTER_SUCCESS, {
				id,
				message: 'Center has been deleted'
			}));
			dispatch(createAction(actionTypes.CLOSE_MODAL));
		}).catch((error) => dispatch(createAction(actionTypes.DELETE_CENTER_FAIL, { message: error.message })));
	};
}

const setActualAsTestCenter = (id: string) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(createAction(actionTypes.SET_TEST_CENTER_INIT));
		HttpService.put(API_CONFIGURATION.path.centers, `actual/convert/${id}`).then(() => {
			dispatch(createAction(actionTypes.SET_TEST_CENTER_SUCCESS, {
				id,
				message: 'Center has been added as test center'
			}));
			dispatch(createAction(actionTypes.CLOSE_MODAL));
		}).catch((error) => dispatch(createAction(actionTypes.SET_TEST_CENTER_FAIL, { message: error.message })));
	};
}

const addActualCenter = (data: TestCenterParamSchema) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(createAction(actionTypes.ADD_ACTUAL_CENTER_INIT));
		HttpService.post(API_CONFIGURATION.path.centers, 'actual', data).then(data => {
			dispatch(createAction(actionTypes.ADD_ACTUAL_CENTER_SUCCESS, {
				center: data.center,
				message: 'Center has been added'
			}));
			dispatch(createAction(actionTypes.CLOSE_MODAL));
		}).catch((error) => dispatch(createAction(actionTypes.ADD_ACTUAL_CENTER_FAIL, { message: error.message })));
	};
}

const editActualCenter = (id: string, data: TestCenterParamSchema) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(createAction(actionTypes.EDIT_ACTUAL_CENTER_INIT));
		HttpService.put(API_CONFIGURATION.path.centers, `actual/${id}`, data).then(() => {
			dispatch(createAction(actionTypes.EDIT_ACTUAL_CENTER_SUCCESS, {
				center: data,
				id,
				message: 'Center has been updated'
			}));
			dispatch(createAction(actionTypes.CLOSE_MODAL));
		}).catch((error) => dispatch(createAction(actionTypes.EDIT_ACTUAL_CENTER_FAIL, { message: error.message })));
	};
}

export {
	fetchTestCenters,
	addTestCenter,
	editTestCenter,
	deleteTestCenter,
	fetchActualCenters,
	setActualAsTestCenter,
	addActualCenter,
	editActualCenter,
}
