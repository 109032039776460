import actionTypes from "store/action-types";
import { Action, ReportPracticeCenterState } from "shared/interface";
import { pageOptions } from "shared/constants/constants";

const initialState: ReportPracticeCenterState = {
	centers: [],
	centerParameters: {
		name: "",
		limit: 10,
		page: 1,
		orderBy: "total",
		order: "desc",
	},
	centerPagination: {
		lastPage: 1,
		page: 1,
		perPage: pageOptions[0],
		total: 1,
	},
};

const reducer = (
	state: ReportPracticeCenterState = initialState,
	action: Action
): ReportPracticeCenterState => {
	switch (action.type) {
		case actionTypes.FETCH_PRACTICE_CENTER_INIT:
			return FETCH_PRACTICE_CENTER_INIT(state, action);
		case actionTypes.FETCH_PRACTICE_CENTER_SUCCESS:
			return FETCH_PRACTICE_CENTER_SUCCESS(state, action);
		default:
			return state;
	}
};

const FETCH_PRACTICE_CENTER_INIT = (
	state: ReportPracticeCenterState,
	action: Action
): ReportPracticeCenterState => {
	return {
		...state,
		centerParameters: action.payload,
		centerPagination: {
			...state.centerPagination,
			page: action.payload.page,
			perPage: action.payload.limit,
		},
	};
};

const FETCH_PRACTICE_CENTER_SUCCESS = (
	state: ReportPracticeCenterState,
	action: Action
): ReportPracticeCenterState => {
	return {
		...state,
		centers: action.payload.centers,
		centerPagination: {
			...state.centerPagination,
			total: action.payload.total,
			lastPage: Math.ceil(
				action.payload.total / state.centerPagination.perPage
			),
		},
	};
};

export default reducer;
