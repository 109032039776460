import React from 'react';
import { Formik, ErrorMessage } from 'formik';
import CustomModal from 'shared/components/modal/modal';
import { Input } from 'shared/components/form/input';
import * as schema from 'shared/constants/validation-schema';
import Button from 'shared/components/form/button';
import FieldErrorMessage from 'shared/components/form/error';
import { PurchasePlan } from 'shared/interface';

interface Props {
	show: boolean;
	loading: boolean;
	initialValues: any;
	userStatus: string;
	availablePurchasePlans: PurchasePlan[];
	onUserAction: (action: string) => void;
	onCancel: () => void;
	onSubmit: (values: any) => void;
}

const userTypeValues = [
	{ key: 'Paid', value: 'paid' },
	{ key: 'Free', value: 'free' },
];

const userTypeValueKeys = userTypeValues.map(v => v.value);

const EditUserForm = (props: Props) => {
	return (
		<CustomModal show={props.show} handleClose={props.onCancel}>
			<div className='form'>
				<h3 className='heading text-center'>Edit User Details</h3>
				<Formik
					initialValues={props.initialValues}
					validateOnBlur
					validateOnChange={false}
					onSubmit={props.onSubmit}
					validationSchema={schema.userEditFormValidationSchema}
				>

					{({ handleSubmit, setFieldValue, values }) => (
						<form onSubmit={handleSubmit}>
							<fieldset>
								<div className='form-group'>
									<Input
										setFieldValue={setFieldValue}
										config={{
											type: 'text',
											label: 'Name',
											name: 'name'
										}}
										placeholder='Name'
										type='text'
										name='name' />
								</div>

								<div className='form-group'>
									<label className='text-capitalize input-label-wrapper'>User Type</label>
									<select
										className='form-control'
										value={values.userType}
										onChange={(e) => {
											const val: string = e.target.value;
											if (userTypeValueKeys.includes(val)) {
												setFieldValue('userType', val);
												if (val === 'paid' && values.currentPlan === '') {
													setFieldValue('currentPlan', props.availablePurchasePlans[0].id);
												}
											}
										}}
									>
										{userTypeValues.map((val) => <option {...val}>{val.key}</option>)}
									</select>
									<ErrorMessage name='userType' component={FieldErrorMessage} />
								</div>

								{values.userType === 'paid' && <div className='form-group'>
									<label className='text-capitalize input-label-wrapper'>User Type</label>
									<select
										className='form-control'
										value={values.currentPlan}
										onChange={(e) => setFieldValue('currentPlan', e.target.value)}
									>
										{props.availablePurchasePlans.map((plan) => <option value={plan.id} key={plan.id}>{plan.title}</option>)}
									</select>
									<ErrorMessage name='currentPlan' component={FieldErrorMessage} />
								</div>}

								<div className='form-group'>
									<Input
										setFieldValue={setFieldValue}
										config={{
											type: 'password',
											label: 'New Password',
											name: 'password'
										}}
										placeholder='New Password'
										type='password'
										name='password' />
								</div>

								<div className='mb-3 mt-3 d-flex align-items-center justify-content-center'>
									<Button
										className='width-100px block m-b mr-1'
										loading={props.loading}
										onClick={props.onCancel}
										type='button'
										btnType='danger'
									>
										CANCEL
                                    </Button>
									<Button
										className='width-100px block m-b'
										loading={props.loading}
										type='submit'
										btnType='primary'
									>
										SUBMIT
                                    </Button>
								</div>
								<div className='mb-3 mt-3 d-flex align-items-center justify-content-center'>
									{props.userStatus === 'active' &&
										<Button
											className='block ml-1 m-b'
											btnType='warning'
											onClick={() => props.onUserAction('disable')}
										>
											disable
										</Button>
									}
									{props.userStatus === 'suspended' &&
										<Button
											className='block ml-1 m-b'
											btnType='info'
											onClick={() => props.onUserAction('enable')}
										>
											enable
										</Button>
									}
								</div>

							</fieldset>
						</form>
					)}
				</Formik>
			</div>
		</CustomModal>
	)
}


export default EditUserForm;
