import React, { useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import queryString from "query-string";

import { State, Action, ModalStatusProps, UserState } from "shared/interface";
import {
	createLoadingSelector,
	createAction,
	debounce,
} from "shared/util/utility";
import { modalStatusProps } from "shared/constants/constants";
import * as UserActions from "./users.action";
import UsersList from "./usersList";
import Confirm from "shared/components/confirm/confirm";
import { confirmMessages } from "shared/constants/messages";
import {
	AppUser,
	UserFetchParameters,
	OptionalUserFetchParameters,
} from "./users.interface";
import { Link, RouteComponentProps } from "react-router-dom";
import UserTraversalList from "./userTraversalList";
import actionTypes from "store/action-types";
import EditUserForm from "./editUserForm";
import PaginationComponent from "shared/components/table/pagination";
import Button from "shared/components/form/button";
import DatePicker from "react-datepicker";

interface MapStateProps extends UserState {
	open: boolean;
	action: string;
	loading: boolean;
	loadingAction: boolean;
	proUserStatus: boolean;
}

interface DispatchProps extends ModalStatusProps {
	fetchUsers: (data: UserFetchParameters) => void;
	disableUser: (userId: string) => void;
	deleteUser: (userId: string) => void;
	enableUser: (userId: string) => void;
	setMessage: (type: string, message: string) => void;
	editUser: (id: string, data: any) => void;
}

interface UIState {
	user: AppUser;
	userPro: AppUser;
	search: {
		name: string;
		email: string;
	};
	searchPro: {
		name: string;
		email: string;
	};
}

const Users = (props: MapStateProps & DispatchProps & RouteComponentProps) => {
	const [user, SetUser] = useState<AppUser>({} as any);
	const [userPro, SetUserPro] = useState<AppUser>({} as any);
	const [showCenters, setShowCenters] = useState<boolean>(false);
	const [pageNum, setPageNum] = useState<number>(1);
	const [search, SetSearch] = useState<any>({
		name: "",
		email: "",
	});
	const [searchPro, SetSearchPro] = useState<any>({
		name: "",
		email: "",
	});
	const [center, setCenter] = useState<any>({
		id: "",
		name: "",
	});
	const didMountRef = useRef(false);

	const onUserAction = (action: string, user?: AppUser) => {
		if (user) {
			SetUser(user);
		}
		props.openModal(action);
	};
	const onUserActionPro = (action: string, userPro?: AppUser) => {
		if (userPro) {
			SetUserPro(userPro);
		}
		props.openModal(action);
	};
	const onCancel = () => {
		SetUser({} as any);
		SetUserPro({} as any);
		props.closeModal();
	};

	const getQuery = (
		data: OptionalUserFetchParameters
	): UserFetchParameters => ({
		name: data.name === undefined ? props.userParameters.name : data.name,
		email:
			data.email === undefined ? props.userParameters.email : data.email,
		status:
			data.status === undefined
				? props.userParameters.status
				: data.status,
		userType:
			data.userType === undefined
				? props.userParameters.userType
				: data.userType,
		limit:
			data.limit === undefined ? props.userParameters.limit : data.limit,
		page: data.page === undefined ? props.userParameters.page : data.page,
		order:
			data.order === undefined ? props.userParameters.order : data.order,
		orderBy:
			data.orderBy === undefined
				? props.userParameters.orderBy
				: data.orderBy,
		userTypeFilter:
			data.userTypeFilter === undefined
				? props.userParameters.userTypeFilter
				: data.userTypeFilter,
		platform:
			data.platform === undefined
				? props.userParameters.platform
				: data.platform,
		paidUserFilter:
			data.paidUserFilter === undefined
				? props.userParameters.paidUserFilter
				: data.paidUserFilter,
	});
	const searchName = debounce((name: string) =>
		props.fetchUsers(getQuery({ name }))
	);
	const searchEmail = debounce((email: string) =>
		props.fetchUsers(getQuery({ email }))
	);
	const handleSearchName = (e: React.ChangeEvent<HTMLInputElement>) => {
		const name = e.target.value;
		if (props.loading) {
			return;
		}
		SetSearch({
			...search,
			name,
		});
		searchName(name);
	};

	const handleEditedProUserData = (proUserData: any, data: any) => {
		let newData = {} as any;
		if (proUserData.fullName !== data.name) {
			newData["name"] = data.name;
		}
		if (proUserData.currentPlan !== data.currentPlan || showCenters) {
			newData["currentplan"] = data.currentPlan;
		}
		if (data.centerId !== "") {
			newData["centerId"] = data.centerId;
		}
		if (proUserData.userType !== data.userType || showCenters) {
			newData["userType"] = data.userType;
		}
		if (data.password) {
			newData["password"] = data.password;
		}
		if (center.id) {
			newData["centerId"] = center.id;
		}
		return newData;
	};
	const handleSearchEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
		const email = e.target.value;
		if (props.loading) {
			return;
		}
		SetSearch({
			...search,
			email,
		});
		searchEmail(email);
	};
	const handleFilterUserType = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const userType = e.target.value;
		if (props.loading) {
			return;
		}
		props.fetchUsers(getQuery({ userType, page: 1 }));
	};
	const handlePlatformChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const platform = e.target.value;
		if (props.loading) {
			return;
		}
		props.fetchUsers(getQuery({ platform, page: 1 }));
	};
	const handleFilterStatus = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const status = e.target.value;
		if (props.loading) {
			return;
		}
		props.fetchUsers(getQuery({ status, page: 1 }));
	};
	const handlePageChange = (page: number) =>
		props.fetchUsers(getQuery({ page }));
	const handlePageSelectionChange = (limit: number) =>
		props.fetchUsers(getQuery({ page: 1, limit }));
	const handleSortChange = (order: "asc" | "desc", orderBy: string) =>
		props.fetchUsers(getQuery({ order, orderBy }));
	const handleUserHeadingClick = () => {
		if (props.action === "view") {
			props.closeModal();
		}
	};
	const handleUserTypeDropdownChange = (
		e: React.ChangeEvent<HTMLSelectElement>
	) => {
		const userTypeFilter = e.target.value;
		if (props.loading) {
			return;
		}
		const updateStateData = {
			userTypeFilter,
			userType: props.userParameters.userType,
		};
		if (userTypeFilter === "paidUsers") {
			updateStateData.userType = "";
		}
		props.fetchUsers(getQuery(updateStateData));
	};

	const onChangeStartDate = (startDate: Date) => {
		console.log(startDate.toISOString().substr(0, 10));
		if (props.loading) {
			return;
		}
		props.fetchUsers(
			getQuery({
				paidUserFilter: {
					startDate,
					endDate: props.userParameters.paidUserFilter.endDate,
				},
			})
		);
	};

	const onChangeEndDate = (endDate: Date) => {
		console.log(endDate);
		if (props.loading) {
			return;
		}
		props.fetchUsers(
			getQuery({
				paidUserFilter: {
					startDate: props.userParameters.paidUserFilter.startDate,
					endDate,
				},
			})
		);
	};

	useEffect(() => {
		if (didMountRef.current) {
			handleUserHeadingClick();
			SetUser({} as any);
			SetUserPro({} as any);
			SetSearch({
				name: "",
				email: "",
			});
			SetSearchPro({
				name: "",
				email: "",
			});

			props.fetchUsers(getQuery({}));
		} else {
			didMountRef.current = true;
		}
	}, [props.proUserStatus]);

	useEffect(() => {
		didMountRef.current = true;
	}, [props.action]);

	useEffect(() => {
		const params: any = queryString.parse(props.location.search);

		if (params.type) {
			// setState({ activeTab: params.type });
			props.fetchUsers(getQuery({ userType: params.type }));
		} else {
			props.fetchUsers(getQuery({}));
		}
	}, []);

	return (
		<div>
			{/* {props.proUserStatus && (
				<>
					<div>
						<div className="justify-content-between d-flex page-heading">
							<div className="heading">
								<Link
									to="/users"
									onClick={handleUserHeadingClick}
								>
									<p>All Users</p>
								</Link>
								{props.action === "view" && (
									<>
										&nbsp; /
										<Link to="/users">
											<p>User Detail</p>
										</Link>
									</>
								)}
							</div>
							<div className="btn-section">
								{props.action === "view" && (
									<Button
										onClick={handleUserHeadingClick}
										className="mr-2 back-btn"
									>
										BACK
									</Button>
								)}
							</div>
						</div>
						{props.action !== "view" && (
							<>
								<div className="d-flex">
									<select
										className="form-control mt-4"
										style={{
											width: "300px",
											marginLeft: "125px",
										}}
										value={
											props.userParametersPro
												.userTypeFilter
										}
										onChange={
											handleUserTypeDropdownChangePro
										}
									>
										<option value="">All</option>
										<option value="drivingDateAdded">
											Users With Driving Date Added
										</option>
										<option value="paidUsers">
											Paid Users
										</option>
									</select>
									{props.userParametersPro.userTypeFilter ===
										"paidUsers" && (
										<>
											<div className="ml-2">
												<label>Start Date</label>
												<DatePicker
													selected={
														props.userParametersPro
															.paidUserFilter
															.startDate
													}
													onChange={
														onChangeStartDatePro
													}
												/>
											</div>
											<div className="ml-2">
												<label>End Date</label>
												<DatePicker
													selected={
														props.userParametersPro
															.paidUserFilter
															.endDate
													}
													onChange={
														onChangeEndDatePro
													}
												/>
											</div>
										</>
									)}
								</div>
								<PaginationComponent
									{...props.userPaginationPro}
									active={props.userPaginationPro.page}
									handlePageChange={handlePageChangePro}
									handlePageSelectionChange={
										handlePageSelectionChangePro
									}
								/>
								<UsersListPro
									userTypeFilter={
										props.userParametersPro.userTypeFilter
									}
									loading={props.loading}
									users={props.usersPro}
									onUserAction={onUserActionPro}
									parameters={{
										name: searchPro.name,
										email: searchPro.email,
										userType:
											props.userParametersPro.userType,
										status: props.userParametersPro.status,
										page: props.userParametersPro.page,
										limit: props.userParametersPro.limit,
										order: props.userParametersPro.order,
										orderBy:
											props.userParametersPro.orderBy,
										platform:
											props.userParametersPro.platform,
									}}
									handleNameChange={handleSearchNamePro}
									handleEmailChange={handleSearchEmailPro}
									handleUserTypeChange={
										handleFilterUserTypePro
									}
									handlePlatformChange={
										handlePlatformChangePro
									}
									handleStatusChange={handleFilterStatusPro}
									handleSortChange={handleSortChangePro}
								/>
							</>
						)}
						{props.open && (
							<>
								<Confirm
									show={props.action === "disable"}
									message={confirmMessages.confirmAction(
										`this user - ${userPro.fullName}`,
										"suspend"
									)}
									loading={props.loadingAction}
									handleClose={onCancel}
									handleConfirm={() =>
										props.disableUserPro(userPro._id)
									}
								/>
								<Confirm
									show={props.action === "delete"}
									message={`${confirmMessages.confirmAction(
										"user",
										"delete"
									)} User can not be recovered later`}
									loading={props.loadingAction}
									handleClose={onCancel}
									handleConfirm={() =>
										props.deleteUserPro(userPro._id)
									}
								/>
								<Confirm
									show={props.action === "enable"}
									message={confirmMessages.confirmAction(
										"user",
										"enable"
									)}
									loading={props.loadingAction}
									handleClose={onCancel}
									handleConfirm={() =>
										props.enableUserPro(userPro._id)
									}
								/>
								{props.action === "view" && (
									<UserTraversalListPro
										user={userPro}
										handleClose={onCancel}
										setMessage={props.setMessagePro}
									/>
								)}
								{props.action === "edit" && (
									<EditUserFormPro
										setCenter={setCenter}
										setShowCenters={setShowCenters}
										showCenters={showCenters}
										center={center}
										show={true}
										userId={userPro._id}
										userPro={userPro}
										setMessage={props.setMessage}
										loading={props.loadingAction}
										availablePurchasePlans={
											props.availablePurchasePlansPro
										}
										initialValues={{
											name: userPro.fullName,
											userType: userPro.userType,
											currentPlan:
												userPro.currentPlan || "",
											centerId: "",
										}}
										userStatus={userPro.status}
										onUserAction={onUserActionPro}
										onCancel={onCancel}
										onSubmit={(data) => {
											if (
												userPro.centerName.length > 0 &&
												!showCenters
											) {
												props.editUserPro(
													userPro._id,
													handleEditedProUserData(
														userPro,
														data
													)
												);
											} else if (
												userPro.centerName.length <= 0
											) {
												props.editUserPro(
													userPro._id,
													handleEditedProUserData(
														userPro,
														data
													)
												);
											} else if (
												userPro.centerName.length > 0 &&
												showCenters
											) {
												props.editUserPro(
													userPro._id,
													handleEditedProUserData(
														userPro,
														data
													)
												);
											}
										}}
									/>
								)}
							</>
						)}
					</div>
				</>
			)} */}
			{!props.proUserStatus && (
				<>
					<div>
						<div className="justify-content-between d-flex page-heading">
							<div className="heading">
								<Link
									to="/users"
									onClick={handleUserHeadingClick}
								>
									<p>All Users</p>
								</Link>
								{props.action === "view" && (
									<>
										&nbsp; /
										<Link to="/users">
											<p>User Detail</p>
										</Link>
									</>
								)}
							</div>
							<div className="btn-section">
								{props.action === "view" && (
									<Button
										onClick={handleUserHeadingClick}
										className="mr-2 back-btn"
									>
										BACK
									</Button>
								)}
							</div>
						</div>
						{props.action !== "view" && (
							<>
								<div className="d-flex">
									<select
										className="form-control mt-4"
										style={{
											width: "300px",
											marginLeft: "125px",
										}}
										value={
											props.userParameters.userTypeFilter
										}
										onChange={handleUserTypeDropdownChange}
									>
										<option value="">All</option>
										<option value="drivingDateAdded">
											Users With Driving Date Added
										</option>
										<option value="paidUsers">
											Paid Users
										</option>
									</select>
									{props.userParameters.userTypeFilter ===
										"paidUsers" && (
										<>
											<div className="ml-2">
												<label>Start Date</label>
												<DatePicker
													selected={
														props.userParameters
															.paidUserFilter
															.startDate
													}
													onChange={onChangeStartDate}
												/>
											</div>
											<div className="ml-2">
												<label>End Date</label>
												<DatePicker
													selected={
														props.userParameters
															.paidUserFilter
															.endDate
													}
													onChange={onChangeEndDate}
												/>
											</div>
										</>
									)}
								</div>
								<PaginationComponent
									{...props.userPagination}
									active={props.userPagination.page}
									handlePageChange={handlePageChange}
									handlePageSelectionChange={
										handlePageSelectionChange
									}
								/>
								<UsersList
									userTypeFilter={
										props.userParameters.userTypeFilter
									}
									loading={props.loading}
									users={props.users}
									onUserAction={onUserAction}
									parameters={{
										name: search.name,
										email: search.email,
										userType: props.userParameters.userType,
										status: props.userParameters.status,
										page: props.userParameters.page,
										limit: props.userParameters.limit,
										order: props.userParameters.order,
										orderBy: props.userParameters.orderBy,
										platform: props.userParameters.platform,
									}}
									handleNameChange={handleSearchName}
									handleEmailChange={handleSearchEmail}
									handleUserTypeChange={handleFilterUserType}
									handlePlatformChange={handlePlatformChange}
									handleStatusChange={handleFilterStatus}
									handleSortChange={handleSortChange}
								/>
							</>
						)}
						{props.open && (
							<>
								<Confirm
									show={props.action === "disable"}
									message={confirmMessages.confirmAction(
										`this user - ${user.fullName}`,
										"suspend"
									)}
									loading={props.loadingAction}
									handleClose={onCancel}
									handleConfirm={() =>
										props.disableUser(user._id)
									}
								/>
								<Confirm
									show={props.action === "delete"}
									message={`${confirmMessages.confirmAction(
										"user",
										"delete"
									)} User can not be recovered later`}
									loading={props.loadingAction}
									handleClose={onCancel}
									handleConfirm={() =>
										props.deleteUser(user._id)
									}
								/>
								<Confirm
									show={props.action === "enable"}
									message={confirmMessages.confirmAction(
										"user",
										"enable"
									)}
									loading={props.loadingAction}
									handleClose={onCancel}
									handleConfirm={() =>
										props.enableUser(user._id)
									}
								/>
								{props.action === "view" && (
									<UserTraversalList
										user={user}
										handleClose={onCancel}
										setMessage={props.setMessage}
									/>
								)}
								{props.action === "edit" && (
									<EditUserForm
										show={true}
										loading={props.loadingAction}
										availablePurchasePlans={
											props.availablePurchasePlans
										}
										initialValues={{
											name: user.fullName,
											userType: user.userType,
											currentPlan: user.currentPlan || "",
										}}
										userStatus={user.status}
										onUserAction={onUserAction}
										onCancel={onCancel}
										onSubmit={(data) =>
											props.editUser(user._id, data)
										}
									/>
								)}
							</>
						)}
					</div>
				</>
			)}
		</div>
	);
};

const loadingSelector = createLoadingSelector(["FETCH_USERS"]);
const loadingActionSelector = createLoadingSelector([
	"DISABLE_USER",
	"DISABLE_USER_PRO",
	"DELETE_USER",
	"DELETE_USER_PRO",
	"EDIT_USER",
	"EDIT_USER_PRO",
	"ENABLE_USER",
	"ENABLE_USER_PRO",
]);

const mapStateToProps = (state: State): MapStateProps => ({
	...state.users,
	...state.common.modalStatus,
	proUserStatus: state.common.proUserStatus,
	loading: loadingSelector(state),
	loadingAction: loadingActionSelector(state),
});

const mapDispatchToProps = (
	dispatch: ThunkDispatch<{}, {}, Action>
): DispatchProps => ({
	...modalStatusProps(dispatch),
	fetchUsers: (data) => dispatch(UserActions.fetchUsers(data)),
	disableUser: (userId) => dispatch(UserActions.disableUser(userId)),
	deleteUser: (userId) => dispatch(UserActions.deleteUser(userId)),
	enableUser: (userId) => dispatch(UserActions.enableUser(userId)),
	editUser: (userId, data) => dispatch(UserActions.editUser(userId, data)),
	setMessage: (type, message) =>
		dispatch(createAction(actionTypes.SET_MESSAGE, { type, message })),
});

export default connect<MapStateProps, DispatchProps, {}, State>(
	mapStateToProps,
	mapDispatchToProps
)(Users);
