import React from 'react';
import { Formik } from 'formik';
import CustomModal from 'shared/components/modal/modal';
import { Input } from 'shared/components/form/input';
import * as schema from 'shared/constants/validation-schema';
import Button from 'shared/components/form/button';
import { TestCenterParamSchema } from '../centers.interface';

interface Props {
    show: boolean;
    loading: boolean;
    title: string;
    initialValues: any;
    onCancel: () => void;
    onSubmit: (values: TestCenterParamSchema, file: File) => void;
}
interface FileSchema {
    file: File;
    error: string;
}

const CenterRouteForm = (props: Props) => {
    const [file, changeFile] = React.useState({ file: {}, error: '' } as FileSchema);
    return (
        <CustomModal show={props.show} handleClose={props.onCancel}>
            <div className='form'>
                <h3 className='heading text-center'>{props.title}</h3>
                <Formik
                    initialValues={props.initialValues}
                    validateOnBlur
                    validateOnChange={false}
                    onSubmit={(data) => {
                        if (!file.file.name && !props.initialValues.filePath) {
                            changeFile({ file: file.file, error: 'please select file' });
                        } else {
                            props.onSubmit(data, file.file);
                        }
                    }}
                    validationSchema={schema.centerRouteFormValidationSchema}
                >

                    {({ handleSubmit, setFieldValue }) => (
                        <form onSubmit={handleSubmit}>
                            <fieldset>
                                <div className='form-group'>
                                    <Input
                                        setFieldValue={setFieldValue}
                                        config={{
                                            type: 'text',
                                            label: 'Center Route Name',
                                            name: 'name'
                                        }}
                                        placeholder='Center Route Name'
                                        type='text'
                                        name='name' />
                                </div>

                                <div className='d-flex'>
                                    <Button
                                        className='height-34px block m-b'
                                        type='button'
                                        onClick={() => (document.getElementById('fileinput-kmlfile') as HTMLElement).click()}
                                        btnType='primary'
                                    >
                                        {props.initialValues.filePath ? 'OVERWRITE' : 'SELECT'} GPX FILE
                                    </Button>

                                    <input
                                        className='hidden'
                                        id='fileinput-kmlfile'
                                        type='file'
                                        onClick={(e) => {
                                            (e.target as any).value = null;
                                        }}
                                        onChange={(e) => {
                                            const file: File = (e.target.files as any)[0];
                                            file && changeFile({ file, error: '' });
                                            (e.target as any).value = null;
                                        }}
                                    />
                                    {!!file.file.name &&
                                        <div className='ml-2 kml-preview'>
                                            <i
                                                className='fa fa-minus'
                                                onClick={() => {
                                                    changeFile({ file: {}, error: '' } as FileSchema);
                                                }}
                                            />
                                            <i className='fa fa-file' />
                                        </div>
                                    }
                                </div>
                                {file.error && <p className='custom-error'>{file.error}</p>}

                                <div className='mb-3 mt-3 d-flex align-items-center justify-content-center'>
                                    <Button
                                        className='width-100px block m-b mr-1'
                                        loading={props.loading}
                                        onClick={props.onCancel}
                                        type='button'
                                        btnType='danger'
                                    >
                                        CANCEL
                                    </Button>
                                    <Button
                                        className='width-100px block m-b'
                                        loading={props.loading}
                                        type='submit'
                                        btnType='primary'
                                    >
                                        SUBMIT
                                    </Button>
                                </div>

                            </fieldset>
                        </form>
                    )}
                </Formik>
			</div>
        </CustomModal>
    )
}


export default CenterRouteForm;