import CryptoJS from 'crypto-js';
import { LoginResponseSchema } from 'features/login/login.interface';
const KEY: string = '1234567890';

const checkLogin = (): boolean => {
	if (localStorage.authData) {
		return true;
	} else {
		return false;
	}
};

const getAccessToken = (): boolean | string => {
	try {
		const data = localStorage.authData;
		if (data) {
			const byteData = CryptoJS.AES.decrypt(data.toString(), KEY);
			const decData: LoginResponseSchema = JSON.parse(byteData.toString(CryptoJS.enc.Utf8));
			return decData && decData.accessToken ? decData.accessToken : false;
		} else {
			return false;
		}
	} catch (e) {
		return false;
	}
};

const getUserLanguage = (): string => {
	return localStorage.lang || 'de';
};

const setAuthData = (data: LoginResponseSchema): void => {
	const cipherText = CryptoJS.AES.encrypt(JSON.stringify(data), KEY);
	localStorage.setItem('authData', cipherText.toString());
};

const getAuthData = (): LoginResponseSchema | {} => {
	const data = localStorage.authData;
	if (data) {
		const byteData = CryptoJS.AES.decrypt(data.toString(), KEY);
		const decData = JSON.parse(byteData.toString(CryptoJS.enc.Utf8));
		return decData;
	} else {
		return {};
	}
};

const removeAuthData = (): void => {
	localStorage.removeItem('authData');
};

const authService = {
	checkLogin: checkLogin,
	getAccessToken: getAccessToken,
	setAuthData: setAuthData,
	getAuthData: getAuthData,
	removeAuthData: removeAuthData,
	getUserLanguage: getUserLanguage,
};

export default authService;
