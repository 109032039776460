import React, { useCallback, useEffect, useRef, useState } from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import {
	withRouter,
	RouteComponentProps,
	Redirect,
	Link,
} from "react-router-dom";
import queryString from "query-string";

import actionTypes from "store/action-types";
import * as LoginActions from "features/login/login.action";
import { AuthState, State, Action, LoaderState } from "shared/interface";
import Confirm from "../confirm/confirm";
import { confirmMessages } from "shared/constants/messages";
import { createLoadingSelector, createAction } from "shared/util/utility";
import authService from "shared/services/auth.service";

interface MapStatePropsSchema extends AuthState, LoaderState {
	proUserStatus: boolean;
}

interface MapDispatchToPropsSchema {
	logout: () => void;
	renderNav: () => void;
	setProUserStatus: (toggle: boolean) => void;
}

interface ComponentState {
	isOpen: boolean;
	lang: string;
}

const TopHeader = (
	props: RouteComponentProps & MapStatePropsSchema & MapDispatchToPropsSchema
) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [lang, setLang] = useState<string>(authService.getUserLanguage());
	const [currentPath, setCurrentPath] = useState("");
	const didMountRef = useRef(false);

	const getActiveLink = (path: string) => {
		const pathToCheck = currentPath;

		if (pathToCheck === "/" && path === "/dashboard") {
			return "nav-active";
		} else if (pathToCheck === path) {
			return "nav-active";
		} else if (
			pathToCheck.split("/").filter(Boolean)[0] ===
			path.split("/").filter(Boolean)[0]
		) {
			return "nav-active";
		}
		return "";
	};

	useEffect(() => {
		return () => {
			window.onpopstate = (e) => {
				setCurrentPath(location.pathname);
			};
		};
	}, [props]);

	useEffect(() => {
		setCurrentPath(props.location.pathname);
	}, [props.location.pathname]);

	const toggleModalStatus = () => setIsOpen(!isOpen);
	const onLogout = () => props.logout();
	const toggleSidebar = () =>
		document.body && document.body.classList.toggle("mini-navbar");

	useEffect(() => {
		const params: any = queryString.parse(props.location.search);
		if (didMountRef.current) {
			if (params.isPro) {
				props.setProUserStatus(true);
			}
		} else {
			didMountRef.current = true;
		}
	}, [props.location.search]);
	useEffect(() => {
		props.setProUserStatus(false);
	}, []);

	return (
		<>
			{!props.authData.auth && <Redirect to="/login" />}
			{props.authData.auth && (
				<div className="row border-bottom navbar-wrapper">
					<nav className="navbar navbar-static-top" role="navigation">
						<ul className="nav navbar-top-links navbar-header">
							<li
								onClick={props.renderNav}
								className={getActiveLink("/dashboard")}
							>
								<Link to="/dashboard">
									<div className="nav-item-link">
										<i className="fa fa-dashboard" />{" "}
										Dashboard
									</div>
								</Link>
							</li>
							<li
								onClick={props.renderNav}
								className={getActiveLink("/centers")}
							>
								<Link to="/centers">
									<div className="nav-item-link">
										<i className="fa fa-area-chart" />{" "}
										Centers
									</div>
								</Link>
							</li>
							<li
								onClick={props.renderNav}
								className={getActiveLink("/users")}
							>
								<Link to="/users">
									<div className="nav-item-link">
										<i className="fa fa-user" /> Users
									</div>
								</Link>
							</li>
							<li
								onClick={props.renderNav}
								className={`dropdown`}
							>
								<button
									className={`${getActiveLink(
										"/actual-test-users"
									)} ${getActiveLink(
										"/practice-users-report"
									)} ${getActiveLink(
										"/practice-center-report"
									)} dropbtn`}
								>
									<i className="fa fa-file" /> Report
								</button>
								<div className="dropdown-content">
									<Link to="/actual-test-users">
										<div className="nav-item-link">
											Actual Test User
										</div>
									</Link>
									<Link to="/practice-users-report">
										<div className="nav-item-link">
											Practice User
										</div>
									</Link>
									<Link to="/practice-center-report">
										<div className="nav-item-link">
											Practice Center
										</div>
									</Link>
								</div>
							</li>
							<li
								onClick={props.renderNav}
								className={getActiveLink("/admin-config")}
							>
								<Link to="/admin-config">
									<div className="nav-item-link">
										<i className="fa fa-user" />{" "}
										Configuration
									</div>
								</Link>
							</li>
						</ul>

						<ul className="nav navbar-top-links navbar-right">
							<li className="logout-li">
								<div
									className="logout-btn"
									onClick={toggleModalStatus}
								>
									<i className="fa fa-power-off" />
								</div>
							</li>
						</ul>
					</nav>
					{isOpen && (
						<Confirm
							show={isOpen}
							handleClose={toggleModalStatus}
							handleConfirm={onLogout}
							loading={props.loading}
							message={confirmMessages.confirmLogout()}
						/>
					)}
				</div>
			)}
		</>
	);
};

const loadingSelector = createLoadingSelector(["AUTH_LOGOUT"]);
const mapStateToProps = (state: State): MapStatePropsSchema => {
	return {
		...state.auth,
		loading: loadingSelector(state),
		proUserStatus: state.common.proUserStatus,
	};
};

const mapDispatchToProps = (
	dispatch: ThunkDispatch<{}, {}, Action>
): MapDispatchToPropsSchema => {
	return {
		logout: () => dispatch(LoginActions.logout()),
		renderNav: () => dispatch(createAction(actionTypes.NO_ACTION)),
		setProUserStatus: (toggle) =>
			dispatch(createAction(actionTypes.SET_PRO_USER, toggle)),
	};
};

export default connect<
	MapStatePropsSchema,
	MapDispatchToPropsSchema,
	{},
	State
>(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(TopHeader));
