import actionTypes from "store/action-types";
import { Action, ActualTestUsersState } from "shared/interface";
import { pageOptions } from "shared/constants/constants";

const initialState: ActualTestUsersState = {
	users: [],
	userParameters: {
		name: "",
		email: "",
		centerName: "",
		limit: 10,
		page: 1,
		status: "",
		userType: "",
		orderBy: "total",
		order: "desc",
		platform: "",
	},
	userPagination: {
		lastPage: 1,
		page: 1,
		perPage: pageOptions[0],
		total: 1,
	},
};

const reducer = (
	state: ActualTestUsersState = initialState,
	action: Action
): ActualTestUsersState => {
	switch (action.type) {
		case actionTypes.FETCH_PRACTICE_USERS_INIT:
			return FETCH_PRACTICE_USERS_INIT(state, action);
		case actionTypes.FETCH_PRACTICE_USERS_SUCCESS:
			return FETCH_PRACTICE_USERS_SUCCESS(state, action);
		default:
			return state;
	}
};

const FETCH_PRACTICE_USERS_INIT = (
	state: ActualTestUsersState,
	action: Action
): ActualTestUsersState => {
	return {
		...state,
		userParameters: action.payload,
		userPagination: {
			...state.userPagination,
			page: action.payload.page,
			perPage: action.payload.limit,
		},
	};
};

const FETCH_PRACTICE_USERS_SUCCESS = (
	state: ActualTestUsersState,
	action: Action
): ActualTestUsersState => {
	return {
		...state,
		users: action.payload.users,
		userPagination: {
			...state.userPagination,
			total: action.payload.total,
			lastPage: Math.ceil(
				action.payload.total / state.userPagination.perPage
			),
		},
	};
};
export default reducer;
