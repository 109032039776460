import { useRef, useEffect, useState } from "react";

import tt from "@tomtom-international/web-sdk-maps";
import { services } from "@tomtom-international/web-sdk-services";

import "@tomtom-international/web-sdk-maps/dist/maps.css";
import { API_CONFIGURATION } from "../../constants/constants";

const API_KEY = API_CONFIGURATION.TOM_TOM_KEY;
const SAN_FRANCISCO = [-122.4194, 37.7749];
const UK = [-1.548567, 53.801277];

const MapTom = ({ path }) => {
	const mapElement = useRef();

	useEffect(() => {
		const key = API_KEY;
		const locations = path;
		const routeOption = {
			key,
			locations: [],
		};

		for (let i = 0; i < path.length; i++) {
			routeOption.locations.push({
				longitude: path[i]?.lng,
				latitude: path[i]?.lat,
			});
		}

		const map = tt.map({
			key: API_KEY,
			container: mapElement.current,
			center: path.length > 0 ? [path[0]?.lng, path[0]?.lat] : UK,
			zoom: 15,
		});

		if (path.length > 0) {
			const last = routeOption.locations.slice(-1)[0];
			const lastStop = [last.longitude, last.latitude];
			new tt.Marker({
				color: "#006500",
			})
				.setLngLat([path[0]?.lng, path[0]?.lat])
				.addTo(map);
			new tt.Marker({
				color: "#720000",
			})
				.setLngLat(lastStop)
				.addTo(map);
			calculateRoute("green", routeOption, map);
		}
	}, [path]);

	const calculateRoute = async (color, routeOptions, map) => {
		const location = routeOptions?.locations?.map((item) => {
			return {
				position: [item.longitude, item.latitude],
			};
		});

		const preparedSupportingPoints = location?.map(function (
			supportingPoint
		) {
			return supportingPoint.position.join();
		});
		var calculateRouteData = {
			key: routeOptions.key,
			traffic: false,
			supportingPoints:
				preparedSupportingPoints.length > 1
					? preparedSupportingPoints
					: undefined,
			locations:
				preparedSupportingPoints[0] +
				":" +
				preparedSupportingPoints[preparedSupportingPoints.length - 1],
		};

		try {
			const response = await services.calculateRoute(calculateRouteData);
			const geojson = response.toGeoJson();

			map.addLayer({
				id: "route",
				type: "line",
				source: {
					type: "geojson",
					data: geojson,
				},
				paint: {
					"line-color": "red",
					"line-width": 5,
				},
			});
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<div className="App">
			<div
				ref={mapElement}
				style={{ height: "calc(100vh - 150px)", width: "100%" }}
				className="mapDiv"
			></div>
		</div>
	);
};

export default MapTom;
