import { connect } from "react-redux";
import AdminConfig from "features/config/config";
// import AdminConfigPro from 'features/config/configPro';
import { State } from "shared/interface";

const ConfigWrapper = ({ proUserStatus }: { proUserStatus: boolean }) => {
	return (
		<div>
			<AdminConfig />
		</div>
	);
};

const mapStateToProps = (state: State) => ({
	proUserStatus: state.common.proUserStatus,
});

export default connect(mapStateToProps)(ConfigWrapper);
