import React from 'react';
import Root from './Root';
import * as serviceWorker from './serviceWorker';
import {createRoot} from "react-dom/client";

const container: any = document.getElementById("root");
const root = createRoot(container);
root.render(
	<Root />
);

serviceWorker.unregister();
