import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import {
	State,
	Action,
	ModalStatusProps,
	ReportPracticeCenterState,
} from "shared/interface";
import {
	createLoadingSelector,
	createAction,
	debounce,
} from "shared/util/utility";
import { modalStatusProps } from "shared/constants/constants";
import * as PracticeCenterActions from "./practice-center-report.action";
import PracticeCenterReport from "./practice-center-report";
import {
	AppPracticeCenter,
	OptionalPracticeCenterFetchParameters,
	PracticeCenterFetchParameters,
} from "./practice-center-report.interface";
import { Link, RouteComponentProps } from "react-router-dom";
import actionTypes from "store/action-types";
import PaginationComponent from "shared/components/table/pagination";

interface MapStateProps extends ReportPracticeCenterState {
	open: boolean;
	action: string;
	loading: boolean;
	loadingAction: boolean;
}

interface DispatchProps extends ModalStatusProps {
	fetchCenters: (data: PracticeCenterFetchParameters) => void;
	setMessage: (type: string, message: string) => void;
}

const PracticeCenterReportContainer = (
	props: MapStateProps & DispatchProps & RouteComponentProps
) => {
	const [centers, setCenters] = useState<AppPracticeCenter>({} as any);
	const [search, setSearch] = useState<any>({ name: "" });

	const onUserAction = (action: string, centers?: AppPracticeCenter) => {
		if (centers) {
			setCenters(centers);
		}
		props.openModal(action);
	};
	const onCancel = () => {
		setCenters({} as any);
		props.closeModal();
	};
	const getQuery = (
		data: OptionalPracticeCenterFetchParameters
	): PracticeCenterFetchParameters => ({
		name: data.name === undefined ? props.centerParameters.name : data.name,
		limit:
			data.limit === undefined
				? props.centerParameters.limit
				: data.limit,
		page: data.page === undefined ? props.centerParameters.page : data.page,
		order:
			data.order === undefined
				? props.centerParameters.order
				: data.order,
		orderBy:
			data.orderBy === undefined
				? props.centerParameters.orderBy
				: data.orderBy,
	});
	const searchName = (name: string) => props.fetchCenters(getQuery({ name }));

	const handleSearchName = (e: React.ChangeEvent<HTMLInputElement>) => {
		const name = e.target.value;
		if (props.loading) {
			return;
		}
		setSearch({ ...search, name });
		searchName(name);
	};
	const handlePageChange = (page: number) =>
		props.fetchCenters(getQuery({ page }));
	const handlePageSelectionChange = (limit: number) =>
		props.fetchCenters(getQuery({ page: 1, limit }));
	const handleSortChange = (order: "asc" | "desc", orderBy: string) =>
		props.fetchCenters(
			getQuery({
				order,
				orderBy,
			})
		);

	useEffect(() => {
		props.fetchCenters(getQuery({}));

		return () => {};
	}, []);

	return (
		<>
			<div>
				<div className="justify-content-between d-flex page-heading">
					<div className="heading">
						<Link to="/practice-center-report">
							<p>Practice Center</p>
						</Link>
					</div>
				</div>
				<>
					<PaginationComponent
						{...props.centerPagination}
						active={props.centerPagination.page}
						handlePageChange={handlePageChange}
						handlePageSelectionChange={
							handlePageSelectionChange
						}
					/>
					<PracticeCenterReport
						loading={props.loading}
						centers={props.centers}
						onUserAction={onUserAction}
						parameters={{
							name: search.name,
							page: props.centerParameters.page,
							limit: props.centerParameters.limit,
							order: props.centerParameters.order,
							orderBy: props.centerParameters.orderBy,
						}}
						handleNameChange={handleSearchName}
						handleSortChange={handleSortChange}
					/>
				</>
			</div>
		</>
	);
};


const loadingSelector = createLoadingSelector(["FETCH_PRACTICE_CENTER"]);
const loadingActionSelector = createLoadingSelector(["ENABLE_USER"]);

const mapStateToProps = (state: State): MapStateProps => ({
	...state.reportPracticeCenter,
	...state.common.modalStatus,
	loading: loadingSelector(state),
	loadingAction: loadingActionSelector(state),
});

const mapDispatchToProps = (
	dispatch: ThunkDispatch<{}, {}, Action>
): DispatchProps => ({
	...modalStatusProps(dispatch),
	fetchCenters: (data) => dispatch(PracticeCenterActions.fetchCenters(data)),
	setMessage: (type, message) =>
		dispatch(createAction(actionTypes.SET_MESSAGE, { type, message })),
});

export default connect<MapStateProps, DispatchProps, {}, State>(
	mapStateToProps,
	mapDispatchToProps
)(PracticeCenterReportContainer);
