import React from "react";

import { CenterRouteSchema } from "../centers.interface";
import Spinner from "shared/components/spinner/spinner";
import EmptyDataContainer from "shared/components/emptyDataContainer/emptyDataContainer";
import Button from "shared/components/form/button";
import { formatDate } from "shared/util/utility";
import { buildFileUrl } from "shared/constants/constants";
// import Maps from 'shared/components/table/map';

interface Props {
	routes: CenterRouteSchema[];
	loading: boolean;
	onRouteAction: (action: string, route: CenterRouteSchema) => void;
}

const CenterRouteList = (props: Props) => {
	return (
		<div className="row stats-wrapper">
			<div className="col-lg-12">
				<div className="ibox float-e-margins">
					<div className="ibox-content">
						<div className="table-responsive">
							<table className="table table-bordered table-hover dataTables-example dataTable">
								<thead>
									<tr>
										<th className="text-capitalize cursor-pointer">
											#
										</th>
										<th className="text-capitalize cursor-pointer">
											Name
										</th>
										<th className="text-capitalize cursor-pointer">
											Created At
										</th>
										<th className="text-capitalize cursor-pointer">
											GPX FIle
										</th>
										<th className="text-capitalize cursor-pointer">
											Action
										</th>
									</tr>
								</thead>
								<tbody className="stats-list">
									{props.loading && (
										<tr>
											<td colSpan={7}>
												<Spinner />
											</td>
										</tr>
									)}
									{!props.loading && !props.routes.length && (
										<tr>
											<td colSpan={7}>
												<EmptyDataContainer />
											</td>
										</tr>
									)}
									{!props.loading &&
										props.routes.map((center, i) => (
											<tr key={i}>
												<td className="Cell">
													{i + 1}
												</td>
												<td className="Cell">
													{center.name}
												</td>
												<td className="Cell">
													{formatDate(
														center.createdAt
													)}
												</td>
												<td className="Cell">
													<Button
														className="action-btn"
														btnType="primary"
													>
														<a
															className="text-white"
															href={
																center.filePath
															}
															target="_blank"
															download={true}
														>
															<i className="fa fa-download" />
														</a>
													</Button>

													<Button
														className="action-btn"
														btnType="primary"
														onClick={() =>
															props.onRouteAction(
																"view",
																center
															)
														}
													>
														<i className="fa fa-eye" />
													</Button>
												</td>
												<td className="Cell">
													<Button
														className="action-btn"
														btnType="info"
														onClick={() =>
															props.onRouteAction(
																"edit",
																center
															)
														}
													>
														<i className="fa fa-pencil" />
													</Button>
													<Button
														className="action-btn"
														btnType="danger"
														onClick={() =>
															props.onRouteAction(
																"delete",
																center
															)
														}
													>
														<i className="fa fa-trash" />
													</Button>
												</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default CenterRouteList;
