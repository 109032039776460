import React from 'react';
import { Redirect } from 'react-router-dom';
import { FormikValues, Formik } from 'formik';

import AuthService from 'shared/services/auth.service';
import { FieldConfiguration, Input } from 'shared/components/form/input';
import { MapDispatchToPropsSchema, MapStatePropsSchema } from './login.interface';
import * as schema from 'shared/constants/validation-schema';
import Button from 'shared/components/form/button';

interface Props extends MapDispatchToPropsSchema, MapStatePropsSchema { }

const LoginComponent = (props: Props) => {
	const isLoggedIn = AuthService.checkLogin();
	if (isLoggedIn) {
		return (<Redirect to='/' />);
	}
	const onLogin = (values: FormikValues) => props.onLogin({ email: values.email, password: values.password });
	return (
		<div className='gray-bg login-wrapper'>
			<div className='middle-box loginscreen animated fadeInDown'>
				<div className='ibox-content'>
					<h2 className='text-align-center'>
						Admin Panel
					</h2>
					<Formik
						initialValues={{ email: '', password: '' }}
						validateOnBlur
						validateOnChange={false}
						onSubmit={onLogin}
						validationSchema={schema.loginFormValidationSchema} >

						{({ handleSubmit, setFieldValue }) => (
							<form onSubmit={handleSubmit}>
								<fieldset>
									{fieldConfig.map((config, index) => (
										<div key={index} className='form-group'>
											<Input
												setFieldValue={setFieldValue}
												config={config}
												placeholder={config.placeHolder || config.label}
												type={config.type}
												name={config.name} />
										</div>
									))}

									<div className='mb-3 mt-3 d-flex align-items-center justify-content-center'>
										<Button
											className='width-100px block m-b'
											loading={props.loading}
											type='submit'
											btnType='primary'>
											Sign In
										</Button>
									</div>

								</fieldset>
							</form>
						)}
					</Formik>
				</div>
			</div>
		</div>

	)
}

const fieldConfig: FieldConfiguration[] = [
	{ type: 'email', label: 'Email', name: 'email' },
	{ type: 'password', label: 'Password', name: 'password' },
];

export default LoginComponent;
