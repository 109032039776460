import { ThunkDispatch } from "redux-thunk";
import actionTypes from "store/action-types";
import { createAction } from "shared/util/utility";
import { Action } from "shared/interface";
import HttpService from "shared/services/http.service";
import { API_CONFIGURATION } from "shared/constants/constants";

const fetchDashboardStats = () => {
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(createAction(actionTypes.FETCH_DASHBOARD_STATS_INIT));
		HttpService.get(API_CONFIGURATION.path.dashboard, "")
			.then((data) => {
				dispatch(
					createAction(
						actionTypes.FETCH_DASHBOARD_STATS_SUCCESS,
						data
					)
				);
			})
			.catch((error) =>
				dispatch(
					createAction(actionTypes.FETCH_DASHBOARD_STATS_FAIL, {
						message: error.message,
					})
				)
			);
	};
};

// const fetchDashboardStatsPro = () => {
// 	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
// 		dispatch(createAction(actionTypes.FETCH_DASHBOARD_STATS_INIT_PRO));
// 		HttpService.get(API_CONFIGURATION.path.dashboardPro, "")
// 			.then((data) => {
// 				dispatch(
// 					createAction(
// 						actionTypes.FETCH_DASHBOARD_STATS_SUCCESS_PRO,
// 						data
// 					)
// 				);
// 			})
// 			.catch((error) =>
// 				dispatch(
// 					createAction(actionTypes.FETCH_DASHBOARD_STATS_FAIL_PRO, {
// 						message: error.message,
// 					})
// 				)
// 			);
// 	};
// };

export { fetchDashboardStats };
