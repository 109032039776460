import { Action, CommonState } from 'shared/interface';
import actionTypes from './action-types';


const initialState: CommonState = {
	notification: {
		type: '',
		message: ''
	},
	modalStatus: {
		open: false,
		action: ''
	},
	proUserStatus: false,
}


const reducer = (state: CommonState = initialState, action: Action): CommonState => {
	switch (action.type) {
		case actionTypes.SET_MESSAGE:
			return { ...state, notification: { type: action.payload.type, message: action.payload.message } }
		case actionTypes.RESET_MESSAGE:
			return { ...state, notification: { type: '', message: '' } }
		case actionTypes.OPEN_MODAL:
			return { ...state, modalStatus: { open: true, action: action.payload } };
		case actionTypes.CLOSE_MODAL:
			return { ...state, modalStatus: { open: false, action: '' } };
		case actionTypes.SET_PRO_USER:
			return {...state, proUserStatus: action.payload}
		default: {
			if (!action.payload || !action.payload.message) {
				return state;
			}
			const matches = /(.*)_(SUCCESS|FAIL)/.exec(action.type);
			if (!matches) {
				return state;
			}
			return {
				...state,
				notification: {
					type: matches.includes('FAIL') ? 'error' : 'success',
					message: action.payload.message
				}
			};
		}
	}
};


export default reducer;
