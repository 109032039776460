import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { State } from "shared/interface";
import ActualTestUsers from "features/actual-test-user/actualTestUsers.container";

interface MapStateProps extends RouteComponentProps<{}> {
	proUserStatus: boolean;
}

const ActualTestUsersWrapper = (props: MapStateProps) => {
	return (
		<div>
			<ActualTestUsers {...props} />
		</div>
	);
};

const mapStateToProps = (state: State) => ({
	proUserStatus: state.common.proUserStatus,
});

export default connect(mapStateToProps)(ActualTestUsersWrapper);
