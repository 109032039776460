import { ThunkDispatch } from "redux-thunk";

import { createAction } from "shared/util/utility";
import { Action } from "shared/interface";
import HttpService from "shared/services/http.service";
import { API_CONFIGURATION } from "shared/constants/constants";

import actionTypes from "store/action-types";
import { PracticeCenterFetchParameters } from "./practice-center-report.interface";

const fetchCenters = (data: PracticeCenterFetchParameters) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(createAction(actionTypes.FETCH_PRACTICE_CENTER_INIT, data));
		HttpService.get(API_CONFIGURATION.path.reportPracticeCenter, "", {
			...data,
		})
			.then((resp) => {
				dispatch(
					createAction(
						actionTypes.FETCH_PRACTICE_CENTER_SUCCESS,
						resp
					)
				);
			})
			.catch((error) => {
				dispatch(
					createAction(actionTypes.FETCH_PRACTICE_CENTER_FAIL, {
						message: error.message,
					})
				);
			});
	};
};

export { fetchCenters };
