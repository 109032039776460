import actionTypes from "store/action-types";
import { UserState, Action } from "shared/interface";
import { pageOptions } from "shared/constants/constants";

const initialState: UserState = {
	users: [],
	availablePurchasePlans: [],
	userParameters: {
		name: "",
		email: "",
		limit: 10,
		page: 1,
		status: "",
		userType: "",
		orderBy: "createdAt",
		order: "desc",
		userTypeFilter: "",
		paidUserFilter: {
			startDate: new Date(`${new Date().getFullYear() - 1}-01-01`),
			endDate: new Date(`${new Date().getFullYear()}-12-31`),
		},
		platform: "",
	},
	userPagination: {
		lastPage: 1,
		page: 1,
		perPage: pageOptions[0],
		total: 1,
	},
};

const reducer = (
	state: UserState = initialState,
	action: Action
): UserState => {
	switch (action.type) {
		case actionTypes.FETCH_USERS_INIT:
			return FETCH_USERS_INIT(state, action);
		case actionTypes.FETCH_USERS_SUCCESS:
			return FETCH_USERS_SUCCESS(state, action);
		case actionTypes.DISABLE_USER_SUCCESS:
			return DISABLE_USER_SUCCESS(state, action);
		case actionTypes.DELETE_USER_SUCCESS:
			return DELETE_USER_SUCCESS(state, action);
		case actionTypes.ENABLE_USER_SUCCESS:
			return ENABLE_USER_SUCCESS(state, action);
		case actionTypes.EDIT_USER_SUCCESS:
			return EDIT_USER_SUCCESS(state, action);
		default:
			return state;
	}
};

const FETCH_USERS_INIT = (state: UserState, action: Action): UserState => {
	return {
		...state,
		userParameters: action.payload,
		userPagination: {
			...state.userPagination,
			page: action.payload.page,
			perPage: action.payload.limit,
		},
	};
};

const FETCH_USERS_SUCCESS = (state: UserState, action: Action): UserState => {
	return {
		...state,
		users: action.payload.users,
		availablePurchasePlans: action.payload.plans,
		userPagination: {
			...state.userPagination,
			total: action.payload.total,
			lastPage: Math.ceil(
				action.payload.total / state.userPagination.perPage
			),
		},
	};
};

const DISABLE_USER_SUCCESS = (state: UserState, action: Action): UserState => {
	const users = [...state.users].map((user) => {
		if (user._id === action.payload.userId) {
			user.status = "suspended";
		}
		return user;
	});
	return {
		...state,
		users,
	};
};

const DELETE_USER_SUCCESS = (state: UserState, action: Action): UserState => {
	const users: any = [...state.users]
		.map((user) => {
			if (user._id === action.payload.userId) {
				return null;
			}
			return user;
		})
		.filter(Boolean);
	return {
		...state,
		users,
	};
};

const ENABLE_USER_SUCCESS = (state: UserState, action: Action): UserState => {
	const users = [...state.users].map((user) => {
		if (user._id === action.payload.userId) {
			user.status = "active";
		}
		return user;
	});
	return {
		...state,
		users,
	};
};

const EDIT_USER_SUCCESS = (state: UserState, action: Action): UserState => {
	const users = [...state.users].map((user) => {
		if (user._id === action.payload.userId) {
			user.fullName = action.payload.name;
			user.userType = action.payload.userType;
			user.currentPlan = action.payload.currentPlan;
		}
		return user;
	});
	return {
		...state,
		users,
	};
};
export default reducer;
