import actionTypes from "store/action-types";
import { Action, DashboardState } from "shared/interface";

const initialState: DashboardState = {
	dashboardData: {
		paidUsers: 0,
		paidUsersBasic: 0,
		paidUsersUpgrade: 0,
		paidUsersAdvanced: 0,
		paidUsersAndroid: 0,
		paidUsersIos: 0,
		paidUsersWeb: 0,
		testCenters: 0,
		actualCenters: 0,
		completedTestTraversals: 0,
		completedActualTraversals: 0,
		cancelledTestTraversals: 0,
		cancelledActualTraversals: 0,
		completedTestTraversalsAndroid: 0,
		completedTestTraversalsIos: 0,
		cancelledTestTraversalsAndroid: 0,
		cancelledTestTraversalsIos: 0,
		completedActualTraversalsAndroid: 0,
		completedActualTraversalsIos: 0,
		cancelledActualTraversalsAndroid: 0,
		cancelledActualTraversalsIos: 0,
		totalUsersIos: 0,
		totalUsersAndroid: 0,
		totalUsersWeb: 0,
		totalPracticeRoutes: 0,
	},
};

const centersReducer = (
	state: DashboardState = initialState,
	action: Action
): DashboardState => {
	switch (action.type) {
		case actionTypes.FETCH_DASHBOARD_STATS_SUCCESS:
			return { ...state, dashboardData: action.payload };
		default:
			return state;
	}
};

export default centersReducer;
