import React from 'react';
import { Formik } from 'formik';
import CustomModal from 'shared/components/modal/modal';
import {Input, FieldConfiguration, InputNumber} from 'shared/components/form/input';
import * as schema from 'shared/constants/validation-schema';
import Button from 'shared/components/form/button';
import { AdminConfigParams } from './config.interface';

interface Props {
    show: boolean;
    loading: boolean;
    initialValues: AdminConfigParams;
    onCancel: () => void;
    onSubmit: (values: AdminConfigParams) => void;
}

const ConfigForm = (props: Props) => {
    return (
        <CustomModal show={props.show} handleClose={props.onCancel}>
            <div className='form'>
                <h3 className='heading text-center'>Admin Config Parameters</h3>
                <Formik
                    initialValues={props.initialValues}
                    validateOnBlur
                    validateOnChange={false}
                    onSubmit={props.onSubmit}
                    validationSchema={schema.adminConfigFormValidationSchema} >

                    {({ handleSubmit, setFieldValue }) => (
                        <form onSubmit={handleSubmit}>
                            <fieldset>
                                {fieldConfig.map((config, index) => (
                                    <div key={index} className='form-group'>
										{
											config.type === 'number' ?
											<>
												<InputNumber
												setFieldValue={setFieldValue}
												config={config}
												placeholder={config.placeHolder || config.label}
												type={config.type}
												min={config.min}
												max={config.max}
												name={config.name}/>
												<span>{config.note}</span>
											</> :
												<><Input
													setFieldValue={setFieldValue}
													config={config}
													placeholder={config.placeHolder || config.label}
													type={config.type}
													name={config.name}/>
													<span>{config.note}</span>
												</>
										}
                                    </div>
                                ))}

                                <div className='mb-3 mt-3 d-flex align-items-center justify-content-center'>
                                    <Button
                                        className='width-100px block m-b'
                                        loading={props.loading}
                                        type='submit'
                                        btnType='primary'>
                                        Submit
                                    </Button>
                                </div>

                            </fieldset>
                        </form>
                    )}
                </Formik>
			</div>
        </CustomModal>
    );
}

const fieldConfig: FieldConfiguration[] = [
	{ type: 'text', label: 'Stripe Secret Key', name: 'stripeSecretKey' },
	{ type: 'text', label: 'Stripe Publishable Key', name: 'stripePublishableKey' },
	// { type: 'text', label: 'Route Lock Message', name: 'routeLockMessage' },
	{ type: 'text', label: 'Plan Expire Message', name: 'planExpireMessage' },
	{ type: 'text', label: 'Free Trial Expire Message', name: 'freeTrialExpireMessage' },
	{ type: 'number', label: 'Trial Period', name: 'paymentDaysAllowed' },
	{ type: 'number', label: 'Total Free Routes', name: 'totalFreeRoutes' },
	{ type: 'number', label: 'Total Free Routes (Basic)', name: 'totalBasicFreeRoutes' },
	// { type: 'number', label: 'Total Traversal Attempts', name: 'totalTraversalAttemts' },
	{ type: 'number', label: 'Payment Amount', name: 'paymentAmount' },
	{ type: 'text', label: 'Payment Currency', name: 'paymentCurrency' },
	{ type: 'text', label: 'Coupon Code', name: 'couponCode' },
	{ type: 'number', label: 'Coupon Code Discount (Percentage)', name: 'couponCodeDiscountPercentage' },
	{ type: 'text', label: 'Subscription Message For App Review', name: 'subscriptionAppReviewMessage' },
	{ type: 'text', label: 'Subscription Message For Live', name: 'subscriptionMessage' },
	{ type: 'number', label: 'Subscription Message For App Review OR Live', name: 'subscriptionMessageReal', min:"0", max: "1",
		note:'Number 1 for Live 0 for App Review' },

];

export default ConfigForm;
