import actionTypes from 'store/action-types';
import { CentersState, Action } from 'shared/interface';
import { pageOptions } from 'shared/constants/constants';

const initialState: CentersState = {
	centers: [],
	pagination: {
		lastPage: 1,
		page: 1,
		perPage: pageOptions[0],
		total: 0,
	},
	search: {
		name: ''
	},
	routeCount: 0
};

const centersReducer = (state: CentersState = initialState, action: Action): CentersState => {
	switch (action.type) {
		case actionTypes.FETCH_TEST_CENTERS_INIT:
		case actionTypes.FETCH_ACTUAL_CENTERS_INIT:
				return FETCH_CENTERS_INIT(state, action);
		case actionTypes.FETCH_TEST_CENTERS_SUCCESS: 
		case actionTypes.FETCH_ACTUAL_CENTERS_SUCCESS: 
				return FETCH_CENTERS_SUCCESS(state, action);
		case actionTypes.ADD_TEST_CENTER_SUCCESS:
		case actionTypes.ADD_ACTUAL_CENTER_SUCCESS:
				return ADD_CENTER_SUCCESS(state, action);
		case actionTypes.EDIT_TEST_CENTER_SUCCESS: return EDIT_CENTER_SUCCESS(state, action);
		case actionTypes.EDIT_ACTUAL_CENTER_SUCCESS: return EDIT_ACTUAL_CENTER_SUCCESS(state, action);
		case actionTypes.DELETE_CENTER_SUCCESS: return DELETE_CENTER_SUCCESS(state, action);
		case actionTypes.SET_TEST_CENTER_SUCCESS: return SET_TEST_CENTER_SUCCESS(state, action);
		default:
			return state;
	}
}

const FETCH_CENTERS_INIT = (state: CentersState, action: Action): CentersState => {
	return {
		...state,
		centers: [],
		search: {
			...state.search,
			name: action.payload.name,
		},
		pagination: {
			...state.pagination,
			page: action.payload.page,
			perPage: action.payload.perPage,
		}
	};
}

const FETCH_CENTERS_SUCCESS = (state: CentersState, action: Action): CentersState => {
	return {
		...state,
		centers: action.payload.centers,
		routeCount: action.payload.routeCount,
		pagination: {
			...state.pagination,
			total: action.payload.total,
			lastPage: Math.ceil(action.payload.total / state.pagination.perPage)
		}
	};
}

const ADD_CENTER_SUCCESS = (state: CentersState, action: Action): CentersState => {
	const centers = [...state.centers];
	centers.unshift(action.payload.center);
	return { ...state, centers, };
}

const EDIT_CENTER_SUCCESS = (state: CentersState, action: Action): CentersState => {
	const centers = state.centers.map(center => {
		if (center._id === action.payload.id) {
			center.name = action.payload.center.name;
			center.radius = action.payload.center.radius;
		}
		return center;
	})
	return { ...state, centers, };
}

const EDIT_ACTUAL_CENTER_SUCCESS = (state: CentersState, action: Action): CentersState => {
	const centers = state.centers.map(center => {
		if (center._id === action.payload.id) {
			center.name = action.payload.center.name;
			center.radius = action.payload.center.radius;
			center.address = {
				latitude: action.payload.center.latitude,
				longitude: action.payload.center.longitude,
				address: action.payload.center.address,
			};
		}
		return center;
	})
	return { ...state, centers, };
}

const DELETE_CENTER_SUCCESS = (state: CentersState, action: Action): CentersState => {
	const centers = [...state.centers];
	let index = -1;
	centers.forEach((center, i) => {
		if (center._id === action.payload.id) {
			index = i;
		}
	});
	if (index !== -1) {
		centers.splice(index, 1);
	}
	return { ...state, centers, };
}

const SET_TEST_CENTER_SUCCESS = (state: CentersState, action: Action): CentersState => {
	const centers = state.centers.map(center => {
		if (center._id === action.payload.id) {
			center.isAddedAsTest = true;
		}
		return center;
	})
	return { ...state, centers, };
}

export default centersReducer;
