import React from 'react';

const NotFound  = () => {
	return (
		<>
			{/* <Header title='404' data={firstLevelBreadcrumbs} /> */}
			<div className='middle-box text-center animated fadeInDown'>
				<h1>404</h1>
				<h3 className='font-bold'>
				Page Not Found
				</h3>

				<div className='error-desc'>
					Sorry, but the page you are looking for does not exists
				</div>
			</div>
		</>
	)
}

export default NotFound;
