import axios, { AxiosResponse, AxiosError } from 'axios';
import { Response } from '../interface';
import AuthService from './auth.service';
import { buildApiUrl } from 'shared/constants/constants';
import _ from 'lodash';
const instance = axios.create();

interface AxiosParams {
	method: string;
	url: string;
	data?: any;
	contentType?: string;
}

interface Url {
	api: string;
	url: string;
}

const get = (url: Url, pathParams: string = '', params: any = {}, { contentType } = { contentType: '' }) => {
	return commonAxios({ method: 'GET', url: buildApiUrl(url, pathParams, params), contentType });
};

const post = (url: Url, pathParams: string = '', params: any = {}, { contentType } = { contentType: '' }) => {
	return commonAxios({ method: 'POST', url: buildApiUrl(url, pathParams), data: params, contentType });
};

const put = (url: Url, pathParams: string = '', params: any = {}, { contentType } = { contentType: '' }) => {
	return commonAxios({ method: 'PUT', url: buildApiUrl(url, pathParams), data: params, contentType });
};

const deleteRequest = (url: Url, pathParams: string = '', params: any = {}, { contentType } = { contentType: '' }) => {
	return commonAxios({ method: 'DELETE', url: buildApiUrl(url, pathParams, params), data: params, contentType });
};

const commonAxios = (axiosParams: AxiosParams): Promise<any> => {
	let {
		method, url, data, contentType
	} = axiosParams;
	let headers: any = {};
	if (!contentType) {
		headers['Content-Type'] = 'application/json';
	} else {
		headers['Content-Type'] = contentType;
	}
	const token = AuthService.getAccessToken() || '';
	if (token) {
		headers['accesstoken'] = token;
	}
	let body: any = null;
	if (contentType === 'application/json') {
		body = JSON.stringify(data);
	} else {
		body = data;
	}
	return new Promise((resolve, reject) => {
		instance({
			method: method,
			url: url,
			headers: headers,
			data: body
		}).then((response: AxiosResponse<Response<any>>) => {
			const data: any = response.data;
			if (data.isError) {
				reject(new Error(data.message));
			} else {
				resolve(data.data);
			}
		}).catch((error: AxiosError) => {
			const message: any = _.get(error, 'response.data.message');
			if (message) {
				reject(new Error(message));
			} else {
				reject(error);
			}
		});
	});
}

const HttpService = {
	get: get,
	post: post,
	put: put,
	deleteRequest: deleteRequest,
};

export {
	instance
}

export default HttpService;
