import React from 'react';
import TopHeader from 'shared/components/navigation/topHeader';

const Layout: React.FC = (props) => {
	return (
		<div id='wrapper'>
			<div id='page-wrapper' className='gray-bg'>
				<TopHeader />
				<div className='page-body'>
					{props.children}
				</div>
			</div>
		</div>
	)
}

export default Layout;
