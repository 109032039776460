import { combineReducers } from 'redux';
import login from 'features/login/login.reducer';
import centersReducer from 'features/centers/centers.reducer';
import dashboardReducer from 'features/dashboard/dashboard.reducer';
import usersReducer from 'features/users/users.reducer';
import reportActualTestUsers from "features/actual-test-user/actualTestUsers.reducer";
import reportTestUsers from "features/practice-user-report/practice-user-report.reducer";
import reportPracticeCenter from "features/practice-center-report/practice-center-report.reducer";

import { State, Action } from 'shared/interface';

import loadingReducer from './loadingReducer';
import commonReducer from './commonReducer';

import actionTypes from 'store/action-types';

const appReducer = combineReducers({
	auth: login,
	loading: loadingReducer,
	dashboard: dashboardReducer,
	centers: centersReducer,
	common: commonReducer,
	users: usersReducer,
	reportActualTestUsers: reportActualTestUsers,
	reportPracticeCenter: reportPracticeCenter,
	reportTestUsers: reportTestUsers
});

const rootReducer = (state: State | undefined, action: Action): State => {
	if (action.type === actionTypes.AUTH_LOGOUT_SUCCESS || action.type === actionTypes.AUTH_LOGOUT_FAIL) {
		localStorage.removeItem('authData');
		state = undefined;
	}
	return appReducer(state, action);
};

export default rootReducer;
