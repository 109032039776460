import * as Yup from 'yup';
import { validationErrorMessages } from 'shared/constants/messages';

const getSchema = (schemaConfig: any): any => {
	return Yup.object().shape(schemaConfig);
}


const getLoginFormValidationSchema = () => Yup.object().shape({
	email: Yup.string().email().trim(validationErrorMessages.trim('Email'))
		.required(validationErrorMessages.required('Email')).strict(true),
	password: Yup.string()
		.required(validationErrorMessages.required('Password')).strict(true)
});

const getTestCenterFormValidationSchema = () => Yup.object().shape({
	name: Yup.string().trim(validationErrorMessages.trim('Center Name'))
		.required(validationErrorMessages.required('Center Name')).strict(true),
	radius: Yup.number().positive().integer().required(validationErrorMessages.required('Radius')).strict(true),
});

const getActualCenterFormValidationSchema = () => Yup.object().shape({
	name: Yup.string().trim(validationErrorMessages.trim('Center Name'))
		.required(validationErrorMessages.required('Center Name')).strict(true),
	latitude: Yup.number().required(validationErrorMessages.required('Latitude')).strict(true),
	longitude: Yup.number().required(validationErrorMessages.required('Longitude')).strict(true),
	address: Yup.string().trim(validationErrorMessages.trim('Address'))
		.required(validationErrorMessages.required('Address')).strict(true),
	radius: Yup.number().positive().integer().required(validationErrorMessages.required('Radius')).strict(true),
});

const getCenterRouteFormValidationSchema = () => Yup.object().shape({
	name: Yup.string().trim(validationErrorMessages.trim('Center Route Name'))
		.required(validationErrorMessages.required('Center Route Name')).strict(true),
});

const getUserEditFormValidationSchema = () => Yup.object().shape({
	name: Yup.string().trim(validationErrorMessages.trim('Name'))
		.required(validationErrorMessages.required('Name')).strict(true),
	password: Yup.string().strict(true),
	currentPlan: Yup.string(),
	userType: Yup.string().trim(validationErrorMessages.trim('User Type'))
		.required(validationErrorMessages.required('User Type')).strict(true),
});

const getUserEditFormProValidationSchema = () => Yup.object().shape({
	name: Yup.string().trim(validationErrorMessages.trim('Name'))
		.required(validationErrorMessages.required('Name')).strict(true),
	password: Yup.string().strict(true),
	currentPlan: Yup.string(),
	paymentId: Yup.string(),
	userType: Yup.string().trim(validationErrorMessages.trim('User Type'))
		.required(validationErrorMessages.required('User Type')).strict(true),
});

const getAdminConfigFormValidationSchema = () => Yup.object().shape({
	stripeSecretKey: Yup.string().trim(validationErrorMessages.trim('Stripe Secret Key'))
		.required(validationErrorMessages.required('Stripe Secret Key')).strict(true),
	stripePublishableKey: Yup.string().trim(validationErrorMessages.trim('Stripe Publishable Key'))
		.required(validationErrorMessages.required('Stripe Publishable Key')).strict(true),
	totalTraversalAttemts: Yup.number().positive()
		.required(validationErrorMessages.required('Total Traversal Attemts')).strict(true),
	paymentAmount: Yup.number().positive()
		.required(validationErrorMessages.required('Payment Amount')).strict(true),
	paymentCurrency: Yup.string().trim(validationErrorMessages.trim('Payment Currency'))
		.required(validationErrorMessages.required('Payment Currency')).strict(true),
});

const getNotificationFormValidationSchema = () => Yup.object().shape({
	device: Yup.string().trim(validationErrorMessages.trim('Device')).required(validationErrorMessages.required('Device')).strict(true),
	action: Yup.string().trim(validationErrorMessages.trim('Action')).required(validationErrorMessages.required('Action')).strict(true),
	title: Yup.string().trim(validationErrorMessages.trim('Title')).required(validationErrorMessages.required('Title')).strict(true),
	message: Yup.string().trim(validationErrorMessages.trim('Message')).required(validationErrorMessages.required('Message')).strict(true),
	topic: Yup.string().trim(validationErrorMessages.trim('Topic')).required(validationErrorMessages.required('Topic')).strict(true),
});

let loginFormValidationSchema = getLoginFormValidationSchema();
let testCenterFormValidationSchema = getTestCenterFormValidationSchema();
let actualCenterFormValidationSchema = getActualCenterFormValidationSchema();
let centerRouteFormValidationSchema = getCenterRouteFormValidationSchema();
let userEditFormValidationSchema = getUserEditFormValidationSchema();
let userEditFormValidationProSchema = getUserEditFormProValidationSchema();
let adminConfigFormValidationSchema = getAdminConfigFormValidationSchema();
let notificationFormValidationSchema = getNotificationFormValidationSchema();

export {
	loginFormValidationSchema,
	getSchema,
	testCenterFormValidationSchema,
	centerRouteFormValidationSchema,
	userEditFormValidationSchema,
	userEditFormValidationProSchema,
	actualCenterFormValidationSchema,
	adminConfigFormValidationSchema,
	notificationFormValidationSchema,
};
