import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { State, Action } from 'shared/interface';
import { createLoadingSelector } from 'shared/util/utility';

import * as LoginActions from './login.action';
import { MapDispatchToPropsSchema, UserLoginData, MapStatePropsSchema } from './login.interface';
import LoginComponent from './login.component';

const loadingSelector = createLoadingSelector(['AUTH']);
const mapStateToProps = (state: State): MapStatePropsSchema => ({
	...state.auth,
	loading: loadingSelector(state)
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, Action>): MapDispatchToPropsSchema => ({
	onLogin: (payload: UserLoginData) => dispatch(LoginActions.login(payload)),
});

export default connect<MapStatePropsSchema, MapDispatchToPropsSchema, {}, State>(mapStateToProps, mapDispatchToProps)(LoginComponent);
