import React from 'react';
import { Pagination } from 'shared/interface';
import Button from '../form/button';
import { pageOptions } from 'shared/constants/constants';

interface Props {
	secondaryTotal?: JSX.Element | null;
	active: number;
	handlePageChange: (page: number) => void;
	handlePageSelectionChange: (perPage: number) => void;
}
// maximum number of pages per pagination
const maxPages = 10;

const PaginationComponent = (props: Pagination & Props) => {
	
	const totalPaginations = Math.ceil(props.lastPage / maxPages);
	const currentPagination = Math.ceil(props.page / maxPages) - 1;
	let lastPage = maxPages * (currentPagination + 1);
	if (props.lastPage < lastPage) {
		lastPage = props.lastPage;
	}
	const pages: JSX.Element[] = [];
	for (let page = currentPagination * maxPages + 1; page <= lastPage; page += 1) {
		pages.push(
			<button
				onClick={() => page !== props.active && props.handlePageChange(page)}
				key={page}
				className={`btn btn-white pagination-btn ${props.active === page ? 'pagination-active' : ''}`}
			>
				{page}
			</button>
		);
	}

	return (
		<div className='table-pagination-wrapper' data-testid='pagination'>
			<div className="page-count">
				Total Records : <span className="font-bold mr-2">{props.total}</span>
				{props.secondaryTotal}
			</div>
			<div className="page-selection">
				<select
					className='form-control'
					value={props.perPage}
					onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
						props.handlePageSelectionChange(parseInt(e.target.value));
					}}
				>
					{pageOptions.map(page => {
						return (
							<option key={page} value={page}>
								{page}
							</option>
						)
					})}
				</select>
			</div>
			<div className='btn-group'>
				<Button
					disabled={currentPagination === 0}
					onClick={() => props.handlePageChange((currentPagination - 1) * maxPages + 1)}
					className='btn btn-white'
					type='button'>
					{'<<'}
				</Button>
				{pages}
				<Button
					onClick={() => props.handlePageChange((currentPagination + 1) * maxPages + 1)}
					disabled={currentPagination === totalPaginations - 1}
					className='btn btn-white'
					type='button'>
					{'>>'}
				</Button>
			</div>
		</div >
	)
}

export default PaginationComponent;