import React from "react";
import { AdminConfigParams } from "./config.interface";
import Button from "shared/components/form/button";

interface Props {
	config: AdminConfigParams;
	onEdit: () => void;
}

const ConfigDetails = (props: Props) => (
	<div className="row stats-wrapper config-wrapper">
		<div className="col-lg-12">
			<div className="ibox float-e-margins">
				<div className="ibox-content">
					<Button
						className="Action-btn"
						btnType="info"
						onClick={props.onEdit}
					>
						<i className="fa fa-pencil" />
					</Button>
					<div className="d-flex">
						<p className="config-label">STRIPE SECRET KEY :</p>
						<p className="config-detail">
							{props.config.stripeSecretKey}
						</p>
					</div>
					<div className="d-flex">
						<p className="config-label">STRIPE PUBLISHABLE KEY :</p>
						<p className="config-detail">
							{props.config.stripePublishableKey}
						</p>
					</div>
					{/* <div className='d-flex'>
                        <p className='config-label'>TOTAL ATTEMPTS :</p>
                        <p className='config-detail'>{props.config.totalTraversalAttemts}</p>
                    </div> */}
					<div className="d-flex">
						<p className="config-label">TRIAL PERIOD TIME :</p>
						<p className="config-detail">
							{props.config.paymentDaysAllowed}
						</p>
					</div>
					<div className="d-flex">
						<p className="config-label">PAYMENT AMOUNT :</p>
						<p className="config-detail">
							{props.config.paymentAmount}
						</p>
					</div>
					<div className="d-flex">
						<p className="config-label">PAYMENT CURRENCY :</p>
						<p className="config-detail">
							{props.config.paymentCurrency}
						</p>
					</div>
					<div className="d-flex">
						<p className="config-label">NUMBER OF FREE ROUTES :</p>
						<p className="config-detail">
							{props.config.totalFreeRoutes}
						</p>
					</div>
					<div className="d-flex">
						<p className="config-label">
							NUMBER OF FREE ROUTES FOR BASIC PLAN :
						</p>
						<p className="config-detail">
							{props.config.totalBasicFreeRoutes}
						</p>
					</div>
					<div className="d-flex">
						<p className="config-label">ROUTE LOCK MESSAGE :</p>
						<p className="config-detail">
							{props.config.routeLockMessage}
						</p>
					</div>
					<div className="d-flex">
						<p className="config-label">ROUTE LOCK MESSAGE :</p>
						<p className="config-detail">
							{props.config.planExpireMessage}
						</p>
					</div>
					<div className="d-flex">
						<p className="config-label">ROUTE LOCK MESSAGE :</p>
						<p className="config-detail">
							{props.config.freeTrialExpireMessage}
						</p>
					</div>
					<div className="d-flex">
						<p className="config-label">COUPON CODE :</p>
						<p className="config-detail">
							{props.config.couponCode}
						</p>
					</div>
					<div className="d-flex">
						<p className="config-label">
							COUPON CODE DISCOUNT PERCENTAGE :
						</p>
						<p className="config-detail">
							{props.config.couponCodeDiscountPercentage}
						</p>
					</div>
					<div className="d-flex">
						<p className="config-label">
							SUBSCRIPTION MESSAGE (APP REVIEW) :
						</p>
						<p className="config-detail">
							{props.config.subscriptionAppReviewMessage}
						</p>
					</div>
					<div className="d-flex">
						<p className="config-label">
							SUBSCRIPTION MESSAGE LIVE :
						</p>
						<p className="config-detail">
							{props.config.subscriptionMessage}
						</p>
					</div>
					<div className="d-flex">
						<p className="config-label">
							SUBSCRIPTION MESSAGE MODE :
						</p>
						<p className="config-detail">
							{props.config.subscriptionMessageReal}
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
);

export default ConfigDetails;
