import React from 'react'
import { State, Action } from 'shared/interface';
import { ThunkDispatch } from 'redux-thunk';
import { createAction } from 'shared/util/utility';
import actionTypes from 'store/action-types';
import { connect } from 'react-redux';
import NotificationComponent from './notification';

interface DispatchProps {
	resetNotification: () => void;
}
interface MapStateProps {
	message: string;
	type: string;
}

const NotificationContainer = (props: MapStateProps & DispatchProps) => {
	return (
		<NotificationComponent
			open={!!props.message}
			message={props.message}
			type={props.type}
			resetNotification={props.resetNotification}
		/>
	);
}

const mapStateToProps = (state: State): MapStateProps => ({
	...state.common.notification,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, Action>): DispatchProps => ({
	resetNotification: () => dispatch(createAction(actionTypes.RESET_MESSAGE))
});

export default connect<MapStateProps, DispatchProps, {}, State>(mapStateToProps, mapDispatchToProps)(NotificationContainer);
