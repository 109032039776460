import React from 'react';
import './spinner.css';

const Loader = (props: { className?: string }) => {
	return (
		<div className={[props.className || '', 'spinner-wrapper'].join(' ')}>
			<div className='loading-icon fa fa-spin fa-spinner' />
		</div>
	);
}

const Spinner: React.FC<{}> = () => {
	return (
		<div className='spinner-wrapper'>
			<div className='spinner' data-testid='spinner' />
		</div>
	);
}

export default Spinner;
export {
	Loader
}
