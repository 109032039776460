import React from "react";

interface Props {
	tabs: {
		tab: string;
		value: string;
	}[];
	className?: string;
	disabled?: string[];
	activeTab: string;
	handleTabChange: (tab: string) => void;
}

/**
 * Tabs component, to show tabs
 * @param tabs - tabs to show
 * @param activeTab - active tab
 * @param handleTabChange - function to handle tab change
 */
const Tabs = (props: Props) => {
	const { tabs, className, disabled, activeTab, handleTabChange } = props;

	return (
		<div className={`col-lg-12 d-flex tab-container ${className}`}>
			{tabs.map((tab) => {
				// const onClick = () => (activeTab !== tab.value) && handleTabChange(tab.value);
				return (
					<div
						key={tab.value}
						className={`tab ${
							activeTab === tab.value ? "tab-active" : ""
						}`}
						onClick={() => {
							if (activeTab !== tab.value) {
								handleTabChange(tab.value);
							}
						}}
					>
						{tab.tab}
					</div>
				);
			})}
		</div>
	);
};

export default Tabs;
