import React, { MouseEventHandler, CSSProperties } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

interface Props {
	btnType?: 'default' | 'primary' | 'warning' | 'danger' | 'info' | '',
	loading?: boolean,
	disabled?: boolean,
	type?: 'submit' | 'button' | 'reset',
	className?: string,
	onClick?: MouseEventHandler<any>;
	title?: string;
	style?: CSSProperties;
	link?: string;
}
const Button: React.FC<Props> = (props) => {
	const onClick: MouseEventHandler<any> = (e) => !props.loading && props.onClick && props.onClick(e);
	const button = (
		<button
			type={props.type}
			className={classnames(
				'btn',
				props.btnType === '' ? '' : `btn-${props.btnType || 'default'}`,
				'text-capitalize', props.className
			)}
			disabled={props.loading || props.disabled}
			style={props.style || {}}
			onClick={!props.disabled && !props.loading ? onClick : () => { }}
		>
			{props.children}
		</button>
	);
	if (!!props.link) {
		return (
			<Link to={props.link}>{button}</Link>
		);
	}
	return button;
}

export default Button;
