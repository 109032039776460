import React, { CSSProperties } from 'react';

interface Props {
    sortParameters?: {
        order: 'asc' | 'desc';
        orderBy: string;
        name: string;
        handleSortChange: (order: 'asc' | 'desc', orderBy: string) => void;
    };
    selectParameters?: {
        value: string;
        onChange: React.ChangeEventHandler<HTMLSelectElement>;
        options: { label: string; value: string }[];
    };
    searchParameters?: {
        value: string;
        onChange: React.ChangeEventHandler<HTMLInputElement>;
        placeholder?: string;
    };
    label?: string;
    header?: boolean;
    width?: string;
    style?: CSSProperties;
}

const TableCell  = (props: Props) => {
    if (!!props.sortParameters) {
        const sortParameters = props.sortParameters;
        return (
            <th
                style={{ width: `${props.width || 'auto'}`, ...props.style }}
                className='text-capitalize cursor-pointer'
                onClick={() => sortParameters.handleSortChange(sortParameters.order === 'asc' ? 'desc' : 'asc', sortParameters.name)}
            >
                <div className='justify-content-between d-flex'>
                    <span>{props.label}</span>
                    {sortParameters.orderBy === sortParameters.name ?
                        <i className={`${sortParameters.order === 'asc' ? 'sorting_asc' : 'sorting_desc'}`} />
                        :
                        <i className='sorting' />
                    }
                </div>
            </th>
        );
    }
    if (!!props.selectParameters) {
        const selectParameters = props.selectParameters;
        return (
            <td>
                <select value={selectParameters.value} onChange={selectParameters.onChange}>
                    {selectParameters.options.map(option => (<option value={option.value} key={option.label}>{option.label}</option>))}
                </select>
            </td>
        )
    }
    if (!!props.searchParameters) {
        const searchParameters = props.searchParameters;
        return (
            <td>
                <input placeholder={searchParameters.placeholder} type='text' value={searchParameters.value} onChange={searchParameters.onChange} />
            </td>
        )
    }
    if (props.header) {
        return (
            <th className='text-capitalize' style={{ width: `${props.width || 'auto'}`, ...props.style }}>{props.label}</th>
        )
    }
    return (<td className='text-capitalize' style={{ width: `${props.width || 'auto'}`, ...props.style }}>{props.label}</td>);
}

export default TableCell;