import React from 'react';
import { Formik } from 'formik';
import CustomModal from 'shared/components/modal/modal';
import {Input, InputNumber} from 'shared/components/form/input';
import * as schema from 'shared/constants/validation-schema';
import Button from 'shared/components/form/button';
import { TestCenterParamSchema } from './centers.interface';

interface Props {
    title: string;
    show: boolean;
    loading: boolean;
    initialValues: any;
    onCancel: () => void;
    onSubmit: (values: TestCenterParamSchema) => void;
}

const TestCenterForm = (props: Props) => {
    return (
        <CustomModal show={props.show} handleClose={props.onCancel}>
            <div className='form'>
                <h3 className='heading text-center'>{props.title}</h3>
                <Formik
                    initialValues={props.initialValues}
                    validateOnBlur
                    validateOnChange={false}
                    onSubmit={props.onSubmit}
                    validationSchema={schema.testCenterFormValidationSchema} >

                    {({ handleSubmit, setFieldValue }) => (
                        <form onSubmit={handleSubmit}>
                            <fieldset>
                                <div className='form-group'>
                                    <Input
                                        setFieldValue={setFieldValue}
                                        config={{
                                            type: 'text',
                                            label: 'Center Name',
                                            name: 'name'
                                        }}
                                        placeholder='Center Name'
                                        type='text'
                                        name='name' />
                                </div>

                                <div className='form-group'>
                                    <InputNumber
                                        setFieldValue={setFieldValue}
                                        config={{
                                            type: 'number',
                                            label: 'Radius',
                                            name: 'radius'
                                        }}
                                        placeholder='Radius'
                                        type='number'
                                        name='radius' />
                                </div>

                                <div className='mb-3 mt-3 d-flex align-items-center justify-content-center'>
                                    <Button
                                        className='width-100px block m-b mr-1'
                                        loading={props.loading}
                                        onClick={props.onCancel}
                                        type='button'
                                        btnType='danger'
                                    >
                                        CANCEL
                                    </Button>
                                    <Button
                                        className='width-100px block m-b'
                                        loading={props.loading}
                                        type='submit'
                                        btnType='primary'
                                    >
                                        SUBMIT
                                    </Button>
                                </div>

                            </fieldset>
                        </form>
                    )}
                </Formik>
			</div>
        </CustomModal>
    )
}


export default TestCenterForm;
