import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Link, RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';

import { State, Action, CentersState, ModalStatusProps } from 'shared/interface';
import { createLoadingSelector, debounce } from 'shared/util/utility';
import Button from 'shared/components/form/button';
import { modalStatusProps } from 'shared/constants/constants';
import Confirm from 'shared/components/confirm/confirm';
import { confirmMessages } from 'shared/constants/messages';

import { TestCenterParamSchema, CenterSchema, ActualCenterParamSchema, CenterFetchParameters } from './centers.interface';
import TestCenterForm from './testCenterForm';
import * as CenterActions from './centers.action';
import TestCentersList from './testCenterList';
import Tabs from 'shared/components/tabs/tabs';
import ActualCenterList from './actualCenterList';
import ActualCenterForm from './actualCenterForm';
import PaginationComponent from 'shared/components/table/pagination';

interface MapStateProps extends CentersState {
	loading: boolean;
	open: boolean;
	action: string;
	loadingAction: boolean;
}

interface DispatchProps extends ModalStatusProps {
	fetchTestCenters: (parameters: CenterFetchParameters) => void;
	fetchActualCenters: (parameters: CenterFetchParameters) => void;
	addTestCenter: (data: TestCenterParamSchema) => void;
	editTestCenter: (id: string, data: TestCenterParamSchema) => void;
	deleteTestCenter: (id: string) => void;
	setActualAsTestCenter: (id: string) => void;
	addActualCenter: (data: ActualCenterParamSchema) => void;
	editActualCenter: (id: string, data: ActualCenterParamSchema) => void;
}

const initialCenter = {
	name: '',
	radius: '',
	address: {
		address: '',
		latitude: '',
		longitude: ''
	}
};


const Centers = (props: (MapStateProps & DispatchProps & RouteComponentProps)) => {
	const [center , setCenter] = useState<any>(initialCenter as any);
	const [activeTab , setActiveTab] = useState<string>('test');
	const [search , setSearch] = useState<any>({ name: '' } as any);


	const getQuery = (query: any) => ({
		perPage: query.perPage === undefined ? props.pagination.perPage : query.perPage,
		page: query.page === undefined ? props.pagination.page : query.page,
		name: query.name === undefined ? props.search.name : query.name,
	})

	const onCancel = () => {
		setCenter(initialCenter as any);
		props.closeModal();
	}

	const onAction = (action: string, center: TestCenterParamSchema) => {
		setCenter(center)
		props.openModal(action);
	}

	const onEditTestCenter = (data: TestCenterParamSchema) => props.editTestCenter(center._id, data);

	const onEditActualCenter = (data: ActualCenterParamSchema) => props.editActualCenter(center._id, data);

	const onTabChange = (activeTabData: string) => {
		setActiveTab(activeTabData);
		setSearch({ name: '' } as any)
		if (activeTabData === 'test') {
			return props.fetchTestCenters(getQuery({ page: 1, name: '' }));
		}
		return props.fetchActualCenters(getQuery({ page: 1, name: '' }));
	}

	const handlePageChange = (page: number) => {
		if (activeTab === 'test') {
			props.fetchTestCenters(getQuery({ page }));
		} else {
			props.fetchActualCenters(getQuery({ page }));
		}
	}

	const handlePageSelectionChange = (perPage: number) => {
		if (activeTab === 'test') {
			props.fetchTestCenters(getQuery({ perPage }));
		} else {
			props.fetchActualCenters(getQuery({ perPage }));
		}
	}

	const searchName = debounce((name: string) => {
		if (activeTab === 'test') {
			props.fetchTestCenters(getQuery({ name }));
		} else {
			props.fetchActualCenters(getQuery({ name }));
		}
	})
	
	const handleSearchName = (e: React.ChangeEvent<HTMLInputElement>) => {
		const name = e.target.value;
		if (props.loading) {
			return;
		}

		setSearch({...search,name})
		searchName(name);
	}

	useEffect(() => {
		const params: any = queryString.parse(props.location.search);
		if (params.type && params.type === 'actual') {
			setActiveTab(params.type)
			props.fetchActualCenters(getQuery({ page : 1}));
		} else {
			props.fetchTestCenters(getQuery({ page : 1}));
		}
		return(() => {
		})
	},[])

	return (
		<>
		<div>
				<div className='justify-content-between d-flex page-heading'>
					<div className='heading'>
						<Link to='/centers'><p>All Centers</p></Link>
					</div>
					<div className='btn-section d-flex'>
						<Tabs
							className='center-tabs'
							activeTab={activeTab}
							handleTabChange={onTabChange}
							tabs={[{ tab: 'Test', value: 'test' }, { tab: 'Actual', value: 'actual' }]}
						/>
						<Button className='btn-add' btnType='primary' onClick={() => onAction('add', { name: '' } as any)}>ADD NEW CENTER</Button>
					</div>
				</div>
				<PaginationComponent
					{...props.pagination}
					secondaryTotal={
						props.routeCount ?
							<>, Total Routes : <span className="mr-2 font-bold">{props.routeCount}</span></> : null
					}
					active={props.pagination.page}
					handlePageChange={handlePageChange}
					handlePageSelectionChange={handlePageSelectionChange}
				/>
				{activeTab === 'test' ?
					<TestCentersList
						search={search}
						onNameChange={handleSearchName}
						centers={props.centers}
						loading={props.loading}
						onCenterAction={onAction}
					/> :
					<ActualCenterList
						search={search}
						onNameChange={handleSearchName}
						centers={props.centers}
						loading={props.loading}
						onCenterAction={onAction}
					/>
				}
				{activeTab === 'test' ?
					<TestCenterForm
						show={props.open && (props.action === 'edit' || props.action === 'add')}
						loading={props.loadingAction}
						title={props.action === 'edit' ? 'Edit Center' : 'Add New Center'}
						initialValues={{ name: center.name, radius: center.radius || 150 }}
						onCancel={onCancel}
						onSubmit={props.action === 'edit' ? onEditTestCenter : props.addTestCenter}
					/> :
					<ActualCenterForm
						show={props.open && (props.action === 'edit' || props.action === 'add')}
						loading={props.loadingAction}
						title={props.action === 'edit' ? 'Edit Center' : 'Add New Center'}
						initialValues={{
							name: center.name,
							radius: center.radius || 150,
							latitude: (center.address || {}).latitude || '',
							longitude: (center.address || {}).longitude || '',
							address: (center.address || {}).address || ''
						}}
						onCancel={onCancel}
						onSubmit={props.action === 'edit' ? onEditActualCenter : props.addActualCenter}
					/>
				}
				<Confirm
					show={props.open && props.action === 'delete'}
					loading={props.loadingAction}
					handleConfirm={() => props.deleteTestCenter(center._id)}
					message={confirmMessages.confirmDelete('center')}
					handleClose={onCancel}
				/>
				<Confirm
					show={props.open && props.action === 'makeTest'}
					loading={props.loadingAction}
					handleConfirm={() => props.setActualAsTestCenter(center._id)}
					message={confirmMessages.confirmAction('set this center', 'as test center')}
					handleClose={onCancel}
				/>
			</div>
		</>
	)
}

const loadingSelector = createLoadingSelector(['FETCH_TEST_CENTERS', 'FETCH_ACTUAL_CENTERS']);
const actionLoadingSelector = createLoadingSelector([
	'ADD_TEST_CENTER', 'EDIT_TEST_CENTER',
	'DELETE_CENTER', 'SET_TEST_CENTER',
	'ADD_ACTUAL_CENTER', 'EDIT_ACTUAL_CENTER'
]);

const mapStateToProps = (state: State): MapStateProps => ({
	...state.centers,
	...state.common.modalStatus,
	loading: loadingSelector(state),
	loadingAction: actionLoadingSelector(state)
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, Action>): DispatchProps => ({
	...modalStatusProps(dispatch),
	fetchTestCenters: (parameters) => dispatch(CenterActions.fetchTestCenters(parameters)),
	fetchActualCenters: (parameters) => dispatch(CenterActions.fetchActualCenters(parameters)),
	addTestCenter: (data) => dispatch(CenterActions.addTestCenter(data)),
	editTestCenter: (id, data) => dispatch(CenterActions.editTestCenter(id, data)),
	deleteTestCenter: (id) => dispatch(CenterActions.deleteTestCenter(id)),
	setActualAsTestCenter: (id) => dispatch(CenterActions.setActualAsTestCenter(id)),
	addActualCenter: (data) => dispatch(CenterActions.addActualCenter(data)),
	editActualCenter: (id, data) => dispatch(CenterActions.editActualCenter(id, data)),
});

export default connect<MapStateProps, DispatchProps, {}, State>(mapStateToProps, mapDispatchToProps)(Centers);
