import React from "react";
import "./emptyDataContainer.css";

interface EmptyDataContainerProps {
	text?: string;
}

const EmptyDataContainer = (props: EmptyDataContainerProps) => {
	return (
		<div className="empty-data-wrapper">
			{props.text || "No data found, please try again later"}
		</div>
	);
};

export default EmptyDataContainer;
