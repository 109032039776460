import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { AdminConfigParams, NotificationFormParams } from "./config.interface";
import ConfigDetails from "./configDetails";
import ConfigForm from "./configForm";
import NotificationForm from "./notificationForm";
import HttpService from "shared/services/http.service";
import { API_CONFIGURATION } from "shared/constants/constants";
import EmptyDataContainer from "shared/components/emptyDataContainer/emptyDataContainer";
import Spinner from "shared/components/spinner/spinner";
import _ from "lodash";
import Button from "shared/components/form/button";

interface UIState {
	loading: boolean;
	loadingAction: boolean;
	action: string;
	configParams: AdminConfigParams;
	notificationParams: NotificationFormParams;
}

const initialNotificationParams = {
	device: "android",
	topic: "GENERAL",
	title: "",
	message: "",
	action: "APP_NOTIFICATION",
};

const AdminConfig = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [loadingAction, setLoadingAction] = useState<boolean>(false);
	const [action, setAction] = useState<string>("");
	const [configParams, setConfigParams] = useState<AdminConfigParams>(
		{} as any
	);
	const [notificationParams, setNotificationParams] =
		useState<NotificationFormParams>(
			_.cloneDeep(initialNotificationParams)
		);

	const fetchAdminConfig = () => {
		setLoading(true);
		HttpService.get(API_CONFIGURATION.path.adminConfig)
			.then((configParams) => {
				setLoading(false);
				setConfigParams(configParams);
			})
			.catch((e) => {
				setLoading(false);
			});
	};
	const onEditConfig = (configParams: AdminConfigParams) => {
		setLoadingAction(true);
		HttpService.put(API_CONFIGURATION.path.adminConfig, "", configParams)
			.then(() => {
				setLoadingAction(false);
				setConfigParams(configParams);
				setAction("");
			})
			.catch((e) => {
				setLoading(false);
			});
	};
	const onNotificationSubmit = (
		notificationParams: NotificationFormParams
	) => {
		setLoadingAction(true);
		HttpService.post(
			API_CONFIGURATION.path.sendNotification,
			"",
			notificationParams
		)
			.then(() => {
				setNotificationParams(_.cloneDeep(initialNotificationParams));
				setLoadingAction(false);
				setAction("");
			})
			.catch((e) => {
				setLoadingAction(false);
			});
	};

	useEffect(() => {
		fetchAdminConfig();
	}, []);

	return (
		<>
			<div>
				<div className="justify-content-between d-flex page-heading">
					<div className="heading">
						<Link to="/admin-config">
							<p>Admin Config Parameters</p>
						</Link>
					</div>
				</div>
				<div className="row stats-wrapper config-wrapper">
					<div className="col-lg-12">
						<Button
							className="Action-btn"
							btnType="info"
							onClick={() => setAction("sendNotification")}
						>
							Send Notification
						</Button>{" "}
						<br />
						<br />
					</div>
				</div>
				{loading ? (
					<Spinner />
				) : (
					<>
						{configParams.stripeSecretKey ? (
							<ConfigDetails
								config={configParams}
								onEdit={() => setAction("edit")}
							/>
						) : (
							<EmptyDataContainer text="no data available" />
						)}
					</>
				)}
				{action === "edit" && (
					<ConfigForm
						show={true}
						loading={loadingAction}
						initialValues={configParams}
						onCancel={() => setAction("")}
						onSubmit={onEditConfig}
					/>
				)}
				{action === "sendNotification" && (
					<NotificationForm
						show={true}
						loading={loadingAction}
						initialValues={notificationParams}
						onCancel={() => setAction("")}
						onSubmit={onNotificationSubmit}
					/>
				)}
			</div>
		</>
	);
};

export default AdminConfig;
