import { ThunkDispatch } from "redux-thunk";

import { createAction } from "shared/util/utility";
import { Action } from "shared/interface";
import HttpService from "shared/services/http.service";
import { API_CONFIGURATION } from "shared/constants/constants";

import actionTypes from "store/action-types";
import { UserFetchParameters } from "./users.interface";

const fetchUsers = (data: UserFetchParameters) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(createAction(actionTypes.FETCH_USERS_INIT, data));
		HttpService.get(API_CONFIGURATION.path.users, "", {
			...data,
			paidUserFilter: JSON.stringify(data.paidUserFilter),
		})
			.then((resp) => {
				dispatch(createAction(actionTypes.FETCH_USERS_SUCCESS, resp));
			})
			.catch((error) => {
				dispatch(
					createAction(actionTypes.FETCH_USERS_FAIL, {
						message: error.message,
					})
				);
			});
	};
};

const disableUser = (userId: string) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(createAction(actionTypes.DISABLE_USER_INIT));
		HttpService.put(API_CONFIGURATION.path.users, `disable/${userId}`)
			.then((resp) => {
				dispatch(
					createAction(actionTypes.DISABLE_USER_SUCCESS, {
						...resp,
						userId,
					})
				);
				dispatch(createAction(actionTypes.CLOSE_MODAL));
			})
			.catch((error) => {
				dispatch(
					createAction(actionTypes.DISABLE_USER_FAIL, {
						message: error.message,
					})
				);
			});
	};
};

const deleteUser = (userId: string) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(createAction(actionTypes.DELETE_USER_INIT));
		HttpService.deleteRequest(API_CONFIGURATION.path.users, userId)
			.then((resp) => {
				dispatch(
					createAction(actionTypes.DELETE_USER_SUCCESS, {
						...resp,
						userId,
					})
				);
				dispatch(createAction(actionTypes.CLOSE_MODAL));
			})
			.catch((error) => {
				dispatch(
					createAction(actionTypes.DELETE_USER_FAIL, {
						message: error.message,
					})
				);
			});
	};
};

const enableUser = (userId: string) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(createAction(actionTypes.ENABLE_USER_INIT));
		HttpService.put(API_CONFIGURATION.path.users, `enable/${userId}`)
			.then((resp) => {
				dispatch(
					createAction(actionTypes.ENABLE_USER_SUCCESS, {
						...resp,
						userId,
					})
				);
				dispatch(createAction(actionTypes.CLOSE_MODAL));
			})
			.catch((error) => {
				dispatch(
					createAction(actionTypes.ENABLE_USER_FAIL, {
						message: error.message,
					})
				);
			});
	};
};

const editUser = (userId: string, data: any) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(createAction(actionTypes.EDIT_USER_INIT));
		HttpService.put(API_CONFIGURATION.path.users, userId, data)
			.then(() => {
				dispatch(
					createAction(actionTypes.EDIT_USER_SUCCESS, {
						...data,
						userId,
					})
				);
				dispatch(createAction(actionTypes.CLOSE_MODAL));
			})
			.catch((error) => {
				dispatch(
					createAction(actionTypes.EDIT_USER_FAIL, {
						message: error.message,
					})
				);
			});
	};
};

export { fetchUsers, disableUser, deleteUser, enableUser, editUser };
