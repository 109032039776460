import React from 'react';
import { Field, ErrorMessage } from 'formik';
import FieldErrorMessage from './error';
import { useTranslation } from 'react-i18next';

export interface FieldConfiguration {
	type: 'text' | 'email' | 'password' | 'number';
	label: string;
	name: string;
	className?: string;
	placeHolder?: string;
	note?: string;
	min?: string;
	max?: string;
}

interface TextFieldProps {
	name: string;
	placeholder: string;
	config: FieldConfiguration;
	setFieldValue: (field: string, value: any) => void;
	
	type?: 'text' | 'email' | 'password' | 'number';
	className?: string;
	autoComplete?: string;
	step?: string;
}

interface NumberFieldProps {
	name: string;
	placeholder: string;
	config: FieldConfiguration;
	setFieldValue: (field: string, value: any) => void;

	type?: 'text' | 'email' | 'password' | 'number';
	className?: string;
	autoComplete?: string;
	step?: string;
	min?: string;
	max?: string;
}

const RenderInput: React.FC<TextFieldProps & { field: any }> = (props) => {
	const { t } = useTranslation();
	switch (props.type) {
		case 'text':
		case 'email':
		case 'password':
			return (
				<>
					{props.config.label &&
					<label className='text-capitalize input-label-wrapper'>
						{props.config.label}
					</label>}
					<input
						{...props.field}
						step={props.step}
						value={getValue(props.field.value)}
						id={props.name}
						type={props.type}
						className={`${props.className || ''} form-control`}
						placeholder={t(props.placeholder)}
						autoComplete={`${props.autoComplete || 'off'}`}
					/>
					<ErrorMessage name={props.config.name} component={FieldErrorMessage} />
				</>
			)
		default:
			return <></>
	}
}
const RenderNumberInput: React.FC<NumberFieldProps & { field: any }> = (props) => {
	const { t } = useTranslation();
	switch (props.type) {
		case 'number':
			return (
				<>
					{props.config.label &&
						<label className='text-capitalize input-label-wrapper'>
							{props.config.label}
						</label>}
					<input
						{...props.field}
						step={props.step}
						value={getValue(props.field.value)}
						id={props.name}
						type={props.type}
						className={`${props.className || ''} form-control`}
						min={props.min}
						max={props.max}
						placeholder={t(props.placeholder)}
						autoComplete={`${props.autoComplete || 'off'}`}
					/>
					<ErrorMessage name={props.config.name} component={FieldErrorMessage} />
				</>
			)
		default:
			return <></>
	}
}
const Input: React.FC<TextFieldProps> = (props) => {
	const fieldRender = ({ field }: { field: any }) => {
		return <RenderInput {...props} field={field} />
	}

	return (
		<Field
			name={props.name}
			render={fieldRender}
		/>
	);
};

const InputNumber: React.FC<NumberFieldProps> = (props) => {
	const fieldRender = ({ field }: { field: any }) => {
		return <RenderNumberInput {...props} field={field} />
	}

	return (
		<Field
			name={props.name}
			render={fieldRender}
		/>
	);
};

const getValue = (value?: string | number) => {
	if (value === undefined || value === null) {
		return '';
	}
	return value;
};

export {
	Input,
	InputNumber
};
