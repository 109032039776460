import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { State } from "shared/interface";
import PracticeUsersCenterReport from "features/practice-user-report/practice-user-center-report/practice-user-center-report.container";

interface MapStateProps extends RouteComponentProps<{}> {
	proUserStatus: boolean;
}

const PracticeUsersCenterReportWrapper = (props: any) => {
	return (
		<div>
			<PracticeUsersCenterReport {...props} />
		</div>
	);
};

const mapStateToProps = (state: State) => ({
	proUserStatus: state.common.proUserStatus,
});

export default connect(mapStateToProps)(PracticeUsersCenterReportWrapper);
