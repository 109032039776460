import { ThunkDispatch } from "redux-thunk";

import { createAction } from "shared/util/utility";
import { Action } from "shared/interface";
import HttpService from "shared/services/http.service";
import { API_CONFIGURATION } from "shared/constants/constants";

import actionTypes from "store/action-types";
import { UserFetchParameters } from "./practice-user-report.interface";

const fetchUsers = (data: UserFetchParameters) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(createAction(actionTypes.FETCH_PRACTICE_USERS_INIT, data));
		HttpService.get(API_CONFIGURATION.path.reportPracticeUsers, "", {
			...data,
		})
			.then((resp) => {
				dispatch(
					createAction(actionTypes.FETCH_PRACTICE_USERS_SUCCESS, resp)
				);
			})
			.catch((error) => {
				dispatch(
					createAction(actionTypes.FETCH_PRACTICE_USERS_FAIL, {
						message: error.message,
					})
				);
			});
	};
};

export { fetchUsers };
