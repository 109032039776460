import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { State } from "shared/interface";
import ActualTestUsersCenterRoutes from "features/actual-test-user/actual-test-users-center/actual-test-users-center-route.container";

interface MapStateProps
	extends RouteComponentProps<{ id: string; centerId: string }> {
	proUserStatus: boolean;
}

const ActualTestUsersCenterRoutesWrapper = (props: any) => {
	return (
		<div>
			<ActualTestUsersCenterRoutes {...props} />
		</div>
	);
};

const mapStateToProps = (state: State) => ({
	proUserStatus: state.common.proUserStatus,
});

export default connect(mapStateToProps)(ActualTestUsersCenterRoutesWrapper);
