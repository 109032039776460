import React from 'react';

import {AppPracticeCenter} from './practice-center-report.interface';
import Spinner from 'shared/components/spinner/spinner';
import EmptyDataContainer from 'shared/components/emptyDataContainer/emptyDataContainer';
import Button from 'shared/components/form/button';
import TableCell from 'shared/components/table/tableCell';

interface Props {
	centers: AppPracticeCenter[];
	loading: boolean;
	parameters: {
		name: string;
		page: number;
		limit: number;
		orderBy: string;
		order: 'asc' | 'desc';
	};
	handleNameChange: React.ChangeEventHandler<HTMLInputElement>;
	onUserAction: (action: string, center: AppPracticeCenter) => void;
	handleSortChange: (order: 'asc' | 'desc', orderBy: string) => void;
}

const PracticeCenterReport = (props: Props) => {
	return (
		<div className='row stats-wrapper'>
			<div className='col-lg-12'>
				<div className='ibox float-e-margins'>
					<div className='ibox-content'>
						<div className='table-responsive'>
							<table className='table table-bordered table-hover dataTables-example dataTable'>
								<thead>
								<tr>
									<TableCell header label='#' width='5%'/>
									<TableCell
										sortParameters={{
											order: props.parameters.order,
											orderBy: props.parameters.orderBy,
											name: 'name',
											handleSortChange: props.handleSortChange
										}}
										label='Name'
										width='10%'
									/>
									<TableCell header
												label='Address'
												width='10%'
									/>
									<TableCell header
												label='Radius'
												width='10%'
									/>
									<TableCell
										sortParameters={{
											order: props.parameters.order,
											orderBy: props.parameters.orderBy,
											name: 'total',
											handleSortChange: props.handleSortChange
										}}
										label='Practice User' width='5%'/>
									<TableCell header label='Action' width='5%' style={{minWidth: '80px'}}/>
								</tr>
								</thead>
								<tbody className='stats-list'>
								<tr>
									<TableCell/>
									<TableCell
										searchParameters={{
											onChange: props.handleNameChange,
											value: props.parameters.name,
											placeholder: 'search name'
										}}
									/>
									<TableCell/>
									<TableCell/>
									<TableCell/>
									<TableCell/>
								</tr>
								{props.loading &&
								<tr>
									<td colSpan={12}>
										<Spinner/>
									</td>
								</tr>
								}
								{!props.loading && !props.centers.length &&
								<tr>
									<td colSpan={12}>
										<EmptyDataContainer/>
									</td>
								</tr>
								}
								{!props.loading &&
								props.centers.map((center, i) => (
									<tr key={i}>
										<td className='Cell'>{(i + 1) + ((props.parameters.page - 1) * props.parameters.limit)}</td>
										<td className='Cell'>{center.name}</td>
										<td className='Cell'>{center.address ? center.address.address : ''}</td>
										<td className='Cell'>{center.radius}</td>
										<td className='Cell'>{center.total}</td>
										<td className='Cell'>
											<Button
												className='action-btn'
												btnType='primary'
												link={`/practice-center-report/${center._id}`}
											>
												<i className='fa fa-eye'/>
											</Button>

										</td>
									</tr>
								))
								}
								</tbody>
							</table>
						</div>

					</div>
				</div>
			</div>
		</div>
	)
}
export default PracticeCenterReport;
