import React from "react";

import { AppUserTest } from "./actualTestUsers.interface";
import Spinner from "shared/components/spinner/spinner";
import EmptyDataContainer from "shared/components/emptyDataContainer/emptyDataContainer";
import Button from "shared/components/form/button";
import TableCell from "shared/components/table/tableCell";
import { formatDate } from "../../shared/util/utility";

interface Props {
	users: AppUserTest[];
	loading: boolean;
	parameters: {
		name: string;
		email: string;
		userType: string;
		centerName: string;
		status: string;
		page: number;
		limit: number;
		orderBy: string;
		order: "asc" | "desc";
		platform: string;
	};
	handleEmailChange: React.ChangeEventHandler<HTMLInputElement>;
	handleNameChange: React.ChangeEventHandler<HTMLInputElement>;
	handleCenterNameChange: React.ChangeEventHandler<HTMLInputElement>;
	handleUserTypeChange: React.ChangeEventHandler<HTMLSelectElement>;
	handlePlatformChange: React.ChangeEventHandler<HTMLSelectElement>;
	handleStatusChange: React.ChangeEventHandler<HTMLSelectElement>;
	onUserAction: (action: string, user: AppUserTest) => void;
	handleSortChange: (order: "asc" | "desc", orderBy: string) => void;
}

const ActualTestUsersList = (props: Props) => {
	return (
		<div className="row stats-wrapper">
			<div className="col-lg-12">
				<div className="ibox float-e-margins">
					<div className="ibox-content">
						<div className="table-responsive">
							<table className="table table-bordered table-hover dataTables-example dataTable">
								<thead>
									<tr>
										<TableCell
											header
											label="#"
											width="5%"
										/>
										<TableCell
											sortParameters={{
												order: props.parameters.order,
												orderBy:
													props.parameters.orderBy,
												name: "email",
												handleSortChange:
													props.handleSortChange,
											}}
											label="Email"
											width="15%"
										/>
										<TableCell
											sortParameters={{
												order: props.parameters.order,
												orderBy:
													props.parameters.orderBy,
												name: "name",
												handleSortChange:
													props.handleSortChange,
											}}
											label="Name"
											width="10%"
										/>
										<TableCell
											sortParameters={{
												order: props.parameters.order,
												orderBy:
													props.parameters.orderBy,
												name: "userType",
												handleSortChange:
													props.handleSortChange,
											}}
											label="User Type"
											width="10%"
											style={{ minWidth: "90px" }}
										/>
										<TableCell
											header
											label="Center Name"
											width="15%"
										/>
										<TableCell
											sortParameters={{
												order: props.parameters.order,
												orderBy:
													props.parameters.orderBy,
												name: "completedAt",
												handleSortChange:
													props.handleSortChange,
											}}
											label="Last Test Date"
											width="10%"
										/>
										<TableCell
											sortParameters={{
												order: props.parameters.order,
												orderBy:
													props.parameters.orderBy,
												name: "total",
												handleSortChange:
													props.handleSortChange,
											}}
											label="Actual Test Routes"
											width="10%"
										/>
										<TableCell
											header
											label="Platform"
											width="5%"
										/>
										<TableCell
											header
											label="Action"
											width="5%"
											style={{ minWidth: "160px" }}
										/>
									</tr>
								</thead>
								<tbody className="stats-list">
									<tr>
										<TableCell />
										<TableCell
											searchParameters={{
												onChange:
													props.handleEmailChange,
												value: props.parameters.email,
												placeholder: "search email",
											}}
										/>
										<TableCell
											searchParameters={{
												onChange:
													props.handleNameChange,
												value: props.parameters.name,
												placeholder: "search name",
											}}
										/>
										<TableCell
											selectParameters={{
												options: [
													{ label: "ALL", value: "" },
													{
														label: "Free",
														value: "free",
													},
													{
														label: "Expired",
														value: "expired",
													},
													{
														label: "Basic",
														value: "basic",
													},
													{
														label: "Upgrade",
														value: "upgrade",
													},
													{
														label: "Advanced",
														value: "advanced",
													},
												],
												onChange:
													props.handleUserTypeChange,
												value: props.parameters
													.userType,
											}}
										/>
										<TableCell
											searchParameters={{
												onChange:
													props.handleCenterNameChange,
												value: props.parameters
													.centerName,
												placeholder:
													"search by center name",
											}}
										/>
										<TableCell />
										<TableCell />
										<TableCell
											selectParameters={{
												options: [
													{ label: "ALL", value: "" },
													{
														label: "Ios",
														value: "ios",
													},
													{
														label: "Android",
														value: "Android",
													},
												],
												onChange:
													props.handlePlatformChange,
												value: props.parameters
													.platform,
											}}
										/>
										<TableCell />
									</tr>
									{props.loading && (
										<tr>
											<td colSpan={12}>
												<Spinner />
											</td>
										</tr>
									)}
									{!props.loading && !props.users.length && (
										<tr>
											<td colSpan={12}>
												<EmptyDataContainer />
											</td>
										</tr>
									)}
									{!props.loading &&
										props.users.map((user, i) => (
											<tr key={i}>
												<td className="Cell">
													{i +
														1 +
														(props.parameters.page -
															1) *
															props.parameters
																.limit}
												</td>
												<td className="Cell">
													{user.email}
												</td>
												<td className="Cell">
													{user.fullName}
												</td>
												<td className="Cell">
													{user.currentPlan
														? user.currentPlan !==
														  "expired"
															? user.currentPlan.substr(
																	26
															  )
															: "Expired"
														: "Free"}
												</td>
												<td className="Cell">
													{user.centerName}
												</td>
												<td className="Cell">
													{formatDate(
														user.completedAt
													)}
												</td>
												<td className="Cell">
													{user.total}
												</td>
												<td className="Cell">
													{user.platform}
												</td>
												<td className="Cell">
													<Button
														className="action-btn"
														btnType="primary"
														link={`/actual-test-users/${user._id}`}
													>
														<i className="fa fa-eye" />
													</Button>
												</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default ActualTestUsersList;
