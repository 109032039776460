import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { State } from "shared/interface";
import ActualTestUsersCenter from "features/actual-test-user/actual-test-users-center/actual-test-users-center.container";

interface MapStateProps extends RouteComponentProps<{ id: string }> {
	proUserStatus: boolean;
}

const ActualTestUsersCenterWrapper = (props: any) => {
	return (
		<div>
			<ActualTestUsersCenter {...props} />
		</div>
	);
};

const mapStateToProps = (state: State) => ({
	proUserStatus: state.common.proUserStatus,
});

export default connect(mapStateToProps)(ActualTestUsersCenterWrapper);
