import React, { useEffect, useState } from "react";

import { UserTraversal, AppUser } from "./users.interface";
import Spinner from "shared/components/spinner/spinner";
import EmptyDataContainer from "shared/components/emptyDataContainer/emptyDataContainer";
import Button from "shared/components/form/button";
import { formatDate } from "shared/util/utility";
import CustomModal from "shared/components/modal/modal";
import HttpService from "shared/services/http.service";
import { API_CONFIGURATION, pageOptions } from "shared/constants/constants";
import { Link } from "react-router-dom";
import Tabs from "shared/components/tabs/tabs";
import Maps from "shared/components/table/map";
import { Pagination } from "shared/interface";
import PaginationComponent from "shared/components/table/pagination";
import AndroidLogo from "assets/images/android.png";
import AppleLogo from "assets/images/apple.png";
import MapTom from "shared/components/table/mapTom";
import { toastMessage } from "shared/components/notification/toastMessage";

interface Props {
	user: AppUser;
	handleClose: () => void;
	setMessage: (type: string, message: string) => void;
}

interface UIState {
	traversals: UserTraversal[];
	loading: boolean;
	tab: "actual" | "test";
	action: string;
	traversal: UserTraversal;
	pagination: Pagination;
}

const UserTraversalList = (props: Props) => {
	const [traversals, setTraversals] = useState<UserTraversal[]>([]);
	const [traversal, setTraversal] = useState<UserTraversal>({} as any);
	const [loading, setLoading] = useState<boolean>(false);
	const [tab, setTab] = useState<string>("test");
	const [action, setAction] = useState<string>("");
	const [pagination, setPagination] = useState<Pagination>({
		lastPage: 1,
		page: 1,
		perPage: pageOptions[0],
		total: 1,
	});

	const getTraversals = (parameters: {
		type: string;
		perPage: number;
		page: number;
	}) => {
		setLoading(true);
		setTab(parameters.type);
		setPagination({
			...pagination,
			perPage: parameters.perPage,
			page: parameters.page,
		});
		HttpService.get(
			API_CONFIGURATION.path.users,
			`traversals/${props.user._id}`,
			parameters
		)
			.then((data) => {
				setLoading(false);
				setTraversals(data.traversals);
				setPagination({
					perPage: parameters.perPage,
					page: parameters.page,
					total: data.total,
					lastPage: Math.ceil(data.total / parameters.perPage),
				});
			})
			.catch((e) => {
				setLoading(false);
				props.setMessage("error", e.message);
			});
	};

	const onTraversalAction = (
		actionData: string,
		traversalData: UserTraversal
	) => {
		if (traversal.id === traversalData.id && action === "view") {
			setAction("");
			setTraversal({} as any);
		}
		setAction(actionData);
		setTraversal(traversalData);
	};

	const handleTabChange = (tabData: string) => {
		if (tabData === "") {
			setAction("payment");
			setTraversal({} as any);
		} else {
			setTab(tabData);
			setAction("");
			setTraversal({} as any);

			getTraversals(getQuery({ type: tabData }));
		}
	};
	const handlePageChange = (page: number) =>
		getTraversals(getQuery({ page }));
	const handlePageSelectionChange = (perPage: number) =>
		getTraversals(getQuery({ page: 1, perPage }));
	const getQuery = (
		data: any
	): { type: string; perPage: number; page: number } => ({
		type: data.type === undefined ? tab : data.type,
		page: data.page === undefined ? pagination.page : data.page,
		perPage: data.perPage === undefined ? pagination.perPage : data.perPage,
	});

	const getGpxFileOfTraversal = (traversalId: string) => {
		HttpService.get(
			API_CONFIGURATION.path.users,
			`traversals/gpx/${traversalId}`
		)
			.then((resp) => {
				const element = document.createElement("a");
				const file = new Blob([resp.gpxFile], {
					type: "application/gpx+xml",
				});
				element.href = URL.createObjectURL(file);
				element.download = "traversal.gpx";
				document.body.appendChild(element); // Required for this to work in FireFox
				element.click();
			})
			.catch((e) => {
				props.setMessage("error", e.message);
			});
	};

	const compareConvertedGpxWithRoute = (traversalId: string) => {
		HttpService.get(
			API_CONFIGURATION.path.users,
			`traversals/gpx/${traversalId}`
		)
			.then((resp) => {
				console.log(resp.gpxFile);
			})
			.catch((e) => {
				props.setMessage("error", e.message);
			});
	};

	const getTraversalsJSX = () => {
		const list = traversals
			.map((traversal, i) => {
				return (
					<tr key={i}>
						<td className="Cell">
							{i + 1 + (pagination.page - 1) * pagination.perPage}
						</td>
						<td className="Cell">
							{formatDate(traversal.startedAt)}
						</td>
						<td className="Cell">
							{formatDate(traversal.completedAt)}
						</td>
						<td className="Cell">
							{traversal.completedAt
								? Math.round(
										(new Date(
											traversal.completedAt
										).getTime() -
											new Date(
												traversal.startedAt
											).getTime()) /
											(1000 * 60)
								  )
								: ""}
						</td>
						<td className="Cell">
							{!!traversal.centerId &&
								(traversal.traversalType === "actual" ? (
									traversal.centerId.name
								) : (
									<Link
										target="blank"
										to={`/centers/${traversal.centerId._id}`}
									>
										{traversal.centerId.name}
									</Link>
								))}
						</td>
						<td className="Cell">
							{traversal.centerRouteId
								? traversal.centerRouteId.name
								: ""}
						</td>
						<td className="Cell">
							{traversal.platform &&
								traversal.platform.toString().toLowerCase() ===
									"android" && (
									<img
										alt="logo"
										src={AndroidLogo}
										className="logo"
									/>
								)}
							{traversal.platform &&
								traversal.platform.toString().toLowerCase() ===
									"ios" && (
									<img
										alt="logo"
										src={AppleLogo}
										className="logo"
									/>
								)}
						</td>
						<td className="Cell">{traversal.status}</td>
						<td className="Cell">
							<Button
								className="action-btn"
								btnType="primary"
								onClick={() => {
									if (traversal?.traversal.length > 0) {
										onTraversalAction("view", traversal);
									} else {
										toastMessage(
											"error",
											"No traversal data found"
										);
									}
								}}
							>
								<i className="fa fa-eye" />
							</Button>
							{!!traversal.traversal.length && (
								<>
									<Button
										btnType="info"
										onClick={() =>
											getGpxFileOfTraversal(traversal.id)
										}
									>
										GPX
									</Button>
									{/* <Button
									btnType='info'
									onClick={() => this.compareConvertedGpxWithRoute(traversal.id)}
								>
									Compare
								</Button> */}
								</>
							)}
						</td>
					</tr>
				);
			})
			.filter(Boolean) as JSX.Element[];
		// if (this.state.action === 'view') {
		// 	list.push(<tr>
		// 		<td colSpan={8}>
		// 			<MapTom path={this.state.traversal.traversal.map(t => ({ lat: t.latitude, lng: t.longitude }))} />
		// 		</td>
		// 	</tr>)
		// }
		return list;
	};

	const traversalsData = getTraversalsJSX();

	useEffect(() => {
		getTraversals(getQuery({ type: tab }));
	}, []);

	return (
		<>
			<div className="row stats-wrapper">
				<Tabs
					tabs={[
						{ tab: "Actual Traversal", value: "actual" },
						{ tab: "Test Traversal", value: "test" },
						{ tab: "View Payment Data", value: "" },
					]}
					activeTab={tab}
					handleTabChange={handleTabChange}
				/>
				<p className="traversal-title">
					{tab} Traversals of - {props.user.fullName} (
					{props.user.email})
					<br />
					Purchase Date -{" "}
					{props.user.paymentData &&
					props.user.paymentData.length &&
					props.user.paymentData[0].createdAt
						? formatDate(props.user.paymentData[0].createdAt)
						: ""}
				</p>
				<div className="col-lg-12">
					<div className="ibox float-e-margins">
						<div className="ibox-content">
							<PaginationComponent
								{...pagination}
								active={pagination.page}
								handlePageChange={handlePageChange}
								handlePageSelectionChange={
									handlePageSelectionChange
								}
							/>
							<div className="table-responsive">
								<table className="table table-bordered table-hover dataTables-example dataTable">
									<thead>
										<tr>
											<th className="text-capitalize cursor-pointer">
												#
											</th>
											<th className="text-capitalize cursor-pointer">
												Started At
											</th>
											<th className="text-capitalize cursor-pointer">
												Completed At
											</th>
											<th className="text-capitalize cursor-pointer">
												Duration (Minutes)
											</th>
											<th className="text-capitalize cursor-pointer">
												Center
											</th>
											<th className="text-capitalize cursor-pointer">
												Route
											</th>
											<th
												className="text-capitalize cursor-pointer"
												style={{ width: "40px" }}
											>
												Platform
											</th>
											<th className="text-capitalize cursor-pointer">
												Status
											</th>
											<th className="text-capitalize cursor-pointer">
												Action
											</th>
										</tr>
									</thead>
									<tbody className="stats-list">
										{loading && (
											<tr>
												<td colSpan={8}>
													<Spinner />
												</td>
											</tr>
										)}
										{!loading && !traversalsData.length && (
											<tr>
												<td colSpan={8}>
													<EmptyDataContainer />
												</td>
											</tr>
										)}
										{!loading && traversalsData}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				{action === "view" && (
					<CustomModal
						size="large"
						show={true}
						handleClose={() => {
							setAction(""), setTraversal({} as any);
						}}
					>
						<MapTom
							path={traversal.traversal.map((t) => ({
								lat: t.latitude,
								lng: t.longitude,
							}))}
						/>
					</CustomModal>
				)}
				{action === "payment" && (
					<CustomModal
						size="large"
						show={true}
						handleClose={() => {
							setAction(""), setTraversal({} as any);
						}}
					>
						<pre>
							{JSON.stringify(props.user.paymentData, null, 2)}
						</pre>
					</CustomModal>
				)}
			</div>
		</>
	);
};

export default UserTraversalList;
