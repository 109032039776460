const AUTH_INIT = "AUTH_INIT";
const AUTH_SUCCESS = "AUTH_SUCCESS";
const AUTH_FAIL = "AUTH_FAIL";

const AUTH_LOGOUT_INIT = "AUTH_LOGOUT_INIT";
const AUTH_LOGOUT_SUCCESS = "AUTH_LOGOUT_SUCCESS";
const AUTH_LOGOUT_FAIL = "AUTH_LOGOUT_FAIL";

const FETCH_DASHBOARD_STATS_INIT = "FETCH_DASHBOARD_STATS_INIT";
const FETCH_DASHBOARD_STATS_SUCCESS = "FETCH_DASHBOARD_STATS_SUCCESS";
const FETCH_DASHBOARD_STATS_FAIL = "FETCH_DASHBOARD_STATS_FAIL";

const FETCH_TEST_CENTERS_INIT = "FETCH_TEST_CENTERS_INIT";
const FETCH_TEST_CENTERS_SUCCESS = "FETCH_TEST_CENTERS_SUCCESS";
const FETCH_TEST_CENTERS_FAIL = "FETCH_TEST_CENTERS_FAIL";
const ADD_TEST_CENTER_INIT = "ADD_TEST_CENTER_INIT";
const ADD_TEST_CENTER_SUCCESS = "ADD_TEST_CENTER_SUCCESS";
const ADD_TEST_CENTER_FAIL = "ADD_TEST_CENTER_FAIL";
const EDIT_TEST_CENTER_INIT = "EDIT_TEST_CENTER_INIT";
const EDIT_TEST_CENTER_SUCCESS = "EDIT_TEST_CENTER_SUCCESS";
const EDIT_TEST_CENTER_FAIL = "EDIT_TEST_CENTER_FAIL";
const DELETE_CENTER_INIT = "DELETE_CENTER_INIT";
const DELETE_CENTER_SUCCESS = "DELETE_CENTER_SUCCESS";
const DELETE_CENTER_FAIL = "DELETE_CENTER_FAIL";

const FETCH_ACTUAL_CENTERS_INIT = "FETCH_ACTUAL_CENTERS_INIT";
const FETCH_ACTUAL_CENTERS_SUCCESS = "FETCH_ACTUAL_CENTERS_SUCCESS";
const FETCH_ACTUAL_CENTERS_FAIL = "FETCH_ACTUAL_CENTERS_FAIL";
const ADD_ACTUAL_CENTER_INIT = "ADD_ACTUAL_CENTER_INIT";
const ADD_ACTUAL_CENTER_SUCCESS = "ADD_ACTUAL_CENTER_SUCCESS";
const ADD_ACTUAL_CENTER_FAIL = "ADD_ACTUAL_CENTER_FAIL";
const EDIT_ACTUAL_CENTER_INIT = "EDIT_ACTUAL_CENTER_INIT";
const EDIT_ACTUAL_CENTER_SUCCESS = "EDIT_ACTUAL_CENTER_SUCCESS";
const EDIT_ACTUAL_CENTER_FAIL = "EDIT_ACTUAL_CENTER_FAIL";
const SET_TEST_CENTER_INIT = "SET_TEST_CENTER_INIT";
const SET_TEST_CENTER_SUCCESS = "SET_TEST_CENTER_SUCCESS";
const SET_TEST_CENTER_FAIL = "SET_TEST_CENTER_FAIL";

const FETCH_USERS_INIT = "FETCH_USERS_INIT";
``;
const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
const FETCH_USERS_FAIL = "FETCH_USERS_FAIL";
const DISABLE_USER_INIT = "DISABLE_USER_INIT";
const DISABLE_USER_SUCCESS = "DISABLE_USER_SUCCESS";
const DISABLE_USER_FAIL = "DISABLE_USER_FAIL";
const ENABLE_USER_INIT = "ENABLE_USER_INIT";
const ENABLE_USER_SUCCESS = "ENABLE_USER_SUCCESS";
const ENABLE_USER_FAIL = "ENABLE_USER_FAIL";
const EDIT_USER_INIT = "EDIT_USER_INIT";
const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
const EDIT_USER_FAIL = "EDIT_USER_FAIL";
const DELETE_USER_INIT = "DELETE_USER_INIT";
const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
const DELETE_USER_FAIL = "DELETE_USER_FAIL";

const NO_ACTION = "NO_ACTION";
const RESET_MESSAGE = "RESET_MESSAGE";
const SET_MESSAGE = "SET_MESSAGE";
const OPEN_MODAL = "OPEN_MODAL";
const CLOSE_MODAL = "CLOSE_MODAL";

const FETCH_ACTUAL_TEST_USERS_INIT = "FETCH_ACTUAL_TEST_USERS_INIT";
const FETCH_ACTUAL_TEST_USERS_SUCCESS = "FETCH_ACTUAL_TEST_USERS_SUCCESS";
const FETCH_ACTUAL_TEST_USERS_FAIL = "FETCH_ACTUAL_TEST_USERS_FAIL";

const FETCH_PRACTICE_USERS_INIT = "FETCH_PRACTICE_USERS_INIT";
const FETCH_PRACTICE_USERS_SUCCESS = "FETCH_PRACTICE_USERS_SUCCESS";
const FETCH_PRACTICE_USERS_FAIL = "FETCH_PRACTICE_USERS_FAIL";

const FETCH_PRACTICE_CENTER_INIT = "FETCH_PRACTICE_CENTER_INIT";
const FETCH_PRACTICE_CENTER_SUCCESS = "FETCH_PRACTICE_CENTER_SUCCESS";
const FETCH_PRACTICE_CENTER_FAIL = "FETCH_PRACTICE_CENTER_FAIL";

const SET_PRO_USER = "SET_PRO_USER";

const actionTypes = {
	AUTH_INIT,
	AUTH_SUCCESS,
	AUTH_FAIL,
	AUTH_LOGOUT_INIT,
	AUTH_LOGOUT_SUCCESS,
	AUTH_LOGOUT_FAIL,

	FETCH_DASHBOARD_STATS_INIT,
	FETCH_DASHBOARD_STATS_SUCCESS,
	FETCH_DASHBOARD_STATS_FAIL,

	FETCH_TEST_CENTERS_INIT,
	FETCH_TEST_CENTERS_SUCCESS,
	FETCH_TEST_CENTERS_FAIL,
	ADD_TEST_CENTER_INIT,
	ADD_TEST_CENTER_SUCCESS,
	ADD_TEST_CENTER_FAIL,
	EDIT_TEST_CENTER_INIT,
	EDIT_TEST_CENTER_SUCCESS,
	EDIT_TEST_CENTER_FAIL,
	DELETE_CENTER_INIT,
	DELETE_CENTER_SUCCESS,
	DELETE_CENTER_FAIL,

	FETCH_ACTUAL_CENTERS_INIT,
	FETCH_ACTUAL_CENTERS_SUCCESS,
	FETCH_ACTUAL_CENTERS_FAIL,
	ADD_ACTUAL_CENTER_INIT,
	ADD_ACTUAL_CENTER_SUCCESS,
	ADD_ACTUAL_CENTER_FAIL,
	EDIT_ACTUAL_CENTER_INIT,
	EDIT_ACTUAL_CENTER_SUCCESS,
	EDIT_ACTUAL_CENTER_FAIL,
	SET_TEST_CENTER_INIT,
	SET_TEST_CENTER_SUCCESS,
	SET_TEST_CENTER_FAIL,

	FETCH_USERS_INIT,
	FETCH_USERS_SUCCESS,
	FETCH_USERS_FAIL,
	DISABLE_USER_INIT,
	DISABLE_USER_SUCCESS,
	DISABLE_USER_FAIL,
	ENABLE_USER_INIT,
	ENABLE_USER_SUCCESS,
	ENABLE_USER_FAIL,
	EDIT_USER_INIT,
	EDIT_USER_SUCCESS,
	EDIT_USER_FAIL,
	DELETE_USER_INIT,
	DELETE_USER_SUCCESS,
	DELETE_USER_FAIL,

	NO_ACTION,
	RESET_MESSAGE,
	SET_MESSAGE,
	OPEN_MODAL,
	CLOSE_MODAL,

	FETCH_ACTUAL_TEST_USERS_INIT,
	FETCH_ACTUAL_TEST_USERS_SUCCESS,
	FETCH_ACTUAL_TEST_USERS_FAIL,

	FETCH_PRACTICE_USERS_INIT,
	FETCH_PRACTICE_USERS_SUCCESS,
	FETCH_PRACTICE_USERS_FAIL,

	FETCH_PRACTICE_CENTER_INIT,
	FETCH_PRACTICE_CENTER_SUCCESS,
	FETCH_PRACTICE_CENTER_FAIL,

	SET_PRO_USER,
};

export default actionTypes;
