import { ThunkDispatch } from 'redux-thunk';

import AuthService from 'shared/services/auth.service';
import { createAction } from 'shared/util/utility';
import { Action } from 'shared/interface';
import HttpService from 'shared/services/http.service';
import { API_CONFIGURATION } from 'shared/constants/constants';

import actionTypes from 'store/action-types';
import { UserLoginData } from './login.interface';


const login = (data: UserLoginData) => {
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(createAction(actionTypes.AUTH_INIT));
		HttpService.post(API_CONFIGURATION.path.login, '', data).then((resp) => {
			AuthService.setAuthData(resp);
			dispatch(createAction(actionTypes.AUTH_SUCCESS));
		}).catch((error) => {
			dispatch(createAction(actionTypes.AUTH_FAIL, { message: error.message }));
		});
	};
}

const logout = () => {
	return (dispatch: ThunkDispatch<{}, {}, Action>) => {
		dispatch(createAction(actionTypes.AUTH_LOGOUT_INIT));
		HttpService.post(API_CONFIGURATION.path.logout).then(() => {
			dispatch(createAction(actionTypes.AUTH_LOGOUT_SUCCESS));
		}).catch((error) => {
			dispatch(createAction(actionTypes.AUTH_LOGOUT_FAIL, { message: error.message }));
		});
	};
}

export {
	login,
	logout,
}

