import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import '@tomtom-international/web-sdk-maps/dist/maps.css'
import tt from '@tomtom-international/web-sdk-maps';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-table/react-table.css';
import 'assets/styles/datatables.min.css'

import './assets/styles/index.scss';
import Layout from 'shared/hoc/layout/component/layout.component'
import AuthService from 'shared/services/auth.service';
import Spinner from 'shared/components/spinner/spinner';

import NotFound from 'shared/components/404/404';
import Login from 'features/login/login.container';
import Centers from 'features/centers/centers.container';
import NotificationContainer from 'shared/components/notification/notificationContainer';
import CenterDetail from 'features/centers/center-detail/center-detail.container';
import Dashboard from 'features/dashboard/dashboard.container';
import Users from 'features/users/users.container';
import ActualTestUsersWrapper from 'features/actual-test-user/actualTestUsersWrapper';
import ActualTestUsersCenterWrapper from 'features/actual-test-user/actual-test-users-center/actual-test-users-center-wrapper';
import ActualTestUsersCenterRoutesWrapper from 'features/actual-test-user/actual-test-users-center/actual-test-users-center-route-wrapper';

import PracticeUsersReportWrapper from 'features/practice-user-report/practice-user-report-wrapper';
import PracticeUsersCenterReportWrapper from 'features/practice-user-report/practice-user-center-report/practice-user-center-report-wrapper';
import PracticeUsersCenterRouteReportWrapper from 'features/practice-user-report/practice-user-center-report/practice-user-center-route-report-wrapper';

import PracticeCenterReportWrapper from 'features/practice-center-report/practice-center-report-wrapper';
import PracticeCenterUserReportWrapper from 'features/practice-center-report/practice-center-user-report/practice-center-user-report-wrapper';
import configWrapper from 'features/config/configWrapper';



const App: React.FC = () => {
	const isLoggedIn = AuthService.checkLogin();
	if (isLoggedIn) {
		return (
			<Layout>
				<NotificationContainer />
				<Suspense fallback={<Spinner />}>
					<Switch>
						<Route exact path='/' render={() => <Redirect to='/dashboard' />} />
						<Route exact path='/centers' component={Centers} />
						<Route exact path='/dashboard' component={Dashboard} />
						<Route exact path='/users' component={Users} />
						<Route exact path='/admin-config' component={configWrapper} />
						<Route exact path='/centers/:id' component={CenterDetail} />
						<Route exact path='/actual-test-users' component={ActualTestUsersWrapper} />
						<Route exact path='/actual-test-users/:id' component={ActualTestUsersCenterWrapper} />
						<Route exact path='/actual-test-users/:id/center/:centerId' component={ActualTestUsersCenterRoutesWrapper} />
						<Route exact path='/practice-users-report' component={PracticeUsersReportWrapper} />
						<Route exact path='/practice-users-report/:id' component={PracticeUsersCenterReportWrapper} />
						<Route exact path='/practice-users-report/:id/center/:centerId' component={PracticeUsersCenterRouteReportWrapper} />
						<Route exact path='/practice-center-report' component={PracticeCenterReportWrapper} />
						<Route exact path='/practice-center-report/:id' component={PracticeCenterUserReportWrapper} />
						<Route exact path='/404' component={NotFound} />

						<Redirect to='/404' />
					</Switch>
				</Suspense>
			</Layout>
		);
	}
	return (
		<>
			<NotificationContainer />
			<Switch>
				<Route path='/login' component={Login} />
				<Redirect to='/login' />
			</Switch>
		</>
	);
}

export default App;
