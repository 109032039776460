import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { State, Action, ModalStatusProps, Pagination } from "shared/interface";
import Button from "shared/components/form/button";
import {
	modalStatusProps,
	API_CONFIGURATION,
	pageOptions,
} from "shared/constants/constants";
import { RouteComponentProps, withRouter } from "react-router";
import { PracticeCenterUser } from "../practice-center-report.interface";
import HttpService from "shared/services/http.service";
import { createAction } from "shared/util/utility";
import actionTypes from "store/action-types";
import { Link } from "react-router-dom";
import Spinner from "../../../shared/components/spinner/spinner";
import EmptyDataContainer from "../../../shared/components/emptyDataContainer/emptyDataContainer";
import PaginationComponent from "../../../shared/components/table/pagination";

interface MapStateProps {
	open: boolean;
	action: string;
	users: PracticeCenterUser[];
}

interface DispatchProps extends ModalStatusProps {
	setMessage: (type: string, message: string) => void;
}

const PracticeCenterUserReportContainer = (
	props: MapStateProps & DispatchProps & RouteComponentProps<{ id: string }>
) => {
	const [users, setUsers] = useState<PracticeCenterUser[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [loadingAction, setLoadingAction] = useState<boolean>(false);
	const [pagination, setPagination] = useState<Pagination>({
		lastPage: 1,
		page: 1,
		perPage: pageOptions[0],
		total: 1,
	});

	const fetchCenterUserList = (parameters: {
		perPage: number;
		page: number;
	}) => {
		setLoading(true);
		setPagination({
			...pagination,
			perPage: parameters.perPage,
			page: parameters.page,
		});
		HttpService.get(
			API_CONFIGURATION.path.reportPracticeCenter,
			props.match.params.id,
			parameters
		)
			.then((data) => {
				setLoading(false);
				setUsers(data.users);
				setPagination({
					// ...pagination,
					perPage: parameters.perPage,
					page: parameters.page,
					total: data.total,
					lastPage: Math.ceil(data.total / parameters.perPage),
				});
			})
			.catch((e) => {
				setLoading(false);
				props.setMessage("error", e.message);
			});
	};

	const handlePageChange = (page: number) =>
		fetchCenterUserList(getQuery({ page: page }));
	const handlePageSelectionChange = (perPage: number) =>
		fetchCenterUserList(getQuery({ page: 1, perPage }));
	const getQuery = (data: any): { perPage: number; page: number } => (console.log(data) ,{

		
		page: data.page === undefined ? pagination.page : data.page,
		perPage: data.perPage === undefined ? pagination.perPage : data.perPage,
	});

	useEffect(() => {
	return(() => {

	})
	},[])

	useEffect(() => {
		fetchCenterUserList(getQuery({}));

		return () => {};
	}, []);

	return (
		<>
			<div>
				<div className="justify-content-between d-flex page-heading">
					<div className="heading">
						<Link to="/practice-users-report">
							<p>Practice Center</p>
						</Link>
						<p>/ User List </p>
					</div>
					<div className="btn-section">
						<Button
							onClick={props.history.goBack}
							className="mr-2 back-btn"
						>
							BACK
						</Button>
					</div>
				</div>

				<div className="row stats-wrapper">
					<div className="col-lg-12">
						<div className="ibox float-e-margins">
							<div className="ibox-content">
								<PaginationComponent
									{...pagination}
									active={pagination.page}
									handlePageChange={handlePageChange}
									handlePageSelectionChange={
										handlePageSelectionChange
									}
								/>
								<div className="table-responsive">
									<table className="table table-bordered table-hover dataTables-example dataTable">
										<thead>
											<tr>
												<th className="text-capitalize cursor-pointer">
													#
												</th>
												<th className="text-capitalize cursor-pointer">
													Name
												</th>
												<th className="text-capitalize cursor-pointer">
													Address
												</th>
												<th className="text-capitalize cursor-pointer">
													Total Practice
												</th>
											</tr>
										</thead>
										<tbody className="stats-list">
											{loading && (
												<tr>
													<td colSpan={8}>
														<Spinner />
													</td>
												</tr>
											)}
											{!loading && !users.length && (
												<tr>
													<td colSpan={8}>
														<EmptyDataContainer />
													</td>
												</tr>
											)}
											{!loading &&
												users.map((user, i) => (
													<tr key={i}>
														<td className="Cell">
															{i + 1}
														</td>
														<td className="Cell">
															{user.fullName}
														</td>
														<td className="Cell">
															{user.email}
														</td>
														<td className="Cell">
															{user.total}
														</td>
													</tr>
												))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

// class PracticeCenterUserReportContainer extends React.Component<MapStateProps & DispatchProps & RouteComponentProps<{ id: string; }>> {
// 	componentDidMount = () => {
// 		this.fetchCenterUserList(this.getQuery({}));

// 	}
// 	state: UIState = {
// 		users: [] as any,
// 		loading: true,
// 		loadingAction: false,
// 		pagination: {
// 			lastPage: 1,
// 			page: 1,
// 			perPage: pageOptions[0],
// 			total: 1,
// 		}
// 	};
// 	fetchCenterUserList = (parameters: { perPage: number; page: number }) => {
// 		this.setState({
// 			loading: true,
// 			pagination: {
// 				...this.state.pagination,
// 				perPage: parameters.perPage,
// 				page: parameters.page,
// 			}
// 		});
// 		HttpService.get(API_CONFIGURATION.path.reportPracticeCenter, this.props.match.params.id, parameters).then((data) => {
// 			this.setState({
// 				loading: false,
// 				users: data.users,
// 				pagination: {
// 					...this.state.pagination,
// 					total: data.total,
// 					lastPage: Math.ceil(data.total / this.state.pagination.perPage)
// 				}
// 			});
// 		}).catch((e) => {
// 			this.setState({ loading: false });
// 			this.props.setMessage('error', e.message);
// 		});
// 	}

// 	handlePageChange = (page: number) => this.fetchCenterUserList(this.getQuery({page: page}));
// 	handlePageSelectionChange = (perPage: number) => this.fetchCenterUserList(this.getQuery({page: 1, perPage}));
// 	getQuery = (data: any): { perPage: number, page: number } => ({
// 		page: data.page === undefined ? this.state.pagination.page : data.page,
// 		perPage: data.perPage === undefined ? this.state.pagination.perPage : data.perPage,
// 	});

// 	render() {
// 		return (
// 			<div>
// 				<div className='justify-content-between d-flex page-heading'>
// 					<div className='heading'>
// 						<Link to='/practice-users-report'><p>Practice Center</p></Link>
// 						<p>/ User List </p>
// 					</div>
// 					<div className='btn-section'>
// 						<Button onClick={this.props.history.goBack} className='mr-2 back-btn'>BACK</Button>
// 					</div>
// 				</div>

// 				<div className='row stats-wrapper'>
// 					<div className='col-lg-12'>
// 						<div className='ibox float-e-margins'>
// 							<div className='ibox-content'>
// 								<PaginationComponent
// 									{...this.state.pagination}
// 									active={this.state.pagination.page}
// 									handlePageChange={this.handlePageChange}
// 									handlePageSelectionChange={this.handlePageSelectionChange}
// 								/>
// 								<div className='table-responsive'>
// 									<table className='table table-bordered table-hover dataTables-example dataTable'>
// 										<thead>
// 										<tr>
// 											<th className='text-capitalize cursor-pointer'>#</th>
// 											<th className='text-capitalize cursor-pointer'>Name</th>
// 											<th className='text-capitalize cursor-pointer'>Address</th>
// 											<th className='text-capitalize cursor-pointer'>Total Practice</th>
// 										</tr>
// 										</thead>
// 										<tbody className='stats-list'>
// 										{this.state.loading &&
// 										<tr>
// 											<td colSpan={8}>
// 												<Spinner/>
// 											</td>
// 										</tr>
// 										}
// 										{!this.state.loading && !this.state.users.length &&
// 										<tr>
// 											<td colSpan={8}>
// 												<EmptyDataContainer/>
// 											</td>
// 										</tr>
// 										}
// 										{!this.state.loading &&
// 										this.state.users.map((user, i) => (
// 											<tr key={i}>
// 												<td className='Cell'>{i + 1}</td>
// 												<td className='Cell'>{user.fullName}</td>
// 												<td className='Cell'>{user.email}</td>
// 												<td className='Cell'>{user.total}</td>
// 											</tr>
// 										))
// 										}
// 										</tbody>
// 									</table>
// 								</div>

// 							</div>
// 						</div>
// 					</div>
// 				</div>
// 			</div>
// 		);
// 	}
// }

const mapStateToProps = (state: State): MapStateProps =>
	({
		...state.common.modalStatus,
		// centers: state.actualTestUsers
	} as MapStateProps);

const mapDispatchToProps = (
	dispatch: ThunkDispatch<{}, {}, Action>
): DispatchProps => ({
	...modalStatusProps(dispatch),
	setMessage: (type, message) =>
		dispatch(createAction(actionTypes.SET_MESSAGE, { type, message })),
});

export default connect<MapStateProps, DispatchProps, {}, State>(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(PracticeCenterUserReportContainer));
