import React from "react";

import { AppUser } from "./users.interface";
import Spinner from "shared/components/spinner/spinner";
import EmptyDataContainer from "shared/components/emptyDataContainer/emptyDataContainer";
import Button from "shared/components/form/button";
import { formatDate } from "shared/util/utility";
import TableCell from "shared/components/table/tableCell";

interface Props {
	users: AppUser[];
	loading: boolean;
	parameters: {
		name: string;
		email: string;
		userType: string;
		status: string;
		page: number;
		limit: number;
		orderBy: string;
		order: "asc" | "desc";
		platform: string;
	};
	userTypeFilter: string;
	handleEmailChange: React.ChangeEventHandler<HTMLInputElement>;
	handleNameChange: React.ChangeEventHandler<HTMLInputElement>;
	handleUserTypeChange: React.ChangeEventHandler<HTMLSelectElement>;
	handlePlatformChange: React.ChangeEventHandler<HTMLSelectElement>;
	handleStatusChange: React.ChangeEventHandler<HTMLSelectElement>;
	onUserAction: (action: string, user: AppUser) => void;
	handleSortChange: (order: "asc" | "desc", orderBy: string) => void;
}

const UsersList = (props: Props) => {
	if (props.userTypeFilter === "drivingDateAdded") {
		return (
			<div className="row stats-wrapper">
				<div className="col-lg-12">
					<div className="ibox float-e-margins">
						<div className="ibox-content">
							<div className="table-responsive">
								<table className="table table-bordered table-hover dataTables-example dataTable">
									<thead>
										<tr>
											<TableCell
												header
												label="#"
												width="5%"
											/>
											<TableCell
												sortParameters={{
													order: props.parameters
														.order,
													orderBy:
														props.parameters
															.orderBy,
													name: "email",
													handleSortChange:
														props.handleSortChange,
												}}
												label="Email"
												width="20%"
											/>
											<TableCell
												sortParameters={{
													order: props.parameters
														.order,
													orderBy:
														props.parameters
															.orderBy,
													name: "name",
													handleSortChange:
														props.handleSortChange,
												}}
												label="Name"
												width="20%"
											/>
											<TableCell
												sortParameters={{
													order: props.parameters
														.order,
													orderBy:
														props.parameters
															.orderBy,
													name: "userType",
													handleSortChange:
														props.handleSortChange,
												}}
												label="User Type"
												width="20%"
												style={{ minWidth: "90px" }}
											/>
											<TableCell
												sortParameters={{
													order: props.parameters
														.order,
													orderBy:
														props.parameters
															.orderBy,
													name: "createdAt",
													handleSortChange:
														props.handleSortChange,
												}}
												label="Created At"
												width="10%"
											/>
											<TableCell
												header
												label="Driving Date & Time"
												width="10%"
											/>
											<TableCell
												header
												label="Platform"
												width="10%"
											/>
											<TableCell
												header
												label="Action"
												width="10%"
												style={{ minWidth: "160px" }}
											/>
										</tr>
									</thead>
									<tbody className="stats-list">
										<tr>
											<TableCell />
											<TableCell
												searchParameters={{
													onChange:
														props.handleEmailChange,
													value: props.parameters
														.email,
													placeholder: "search email",
												}}
											/>
											<TableCell
												searchParameters={{
													onChange:
														props.handleNameChange,
													value: props.parameters
														.name,
													placeholder: "search name",
												}}
											/>
											<TableCell
												selectParameters={{
													options: [
														{
															label: "ALL",
															value: "",
														},
														{
															label: "Free",
															value: "free",
														},
														{
															label: "Basic",
															value: "basic",
														},
														{
															label: "Upgrade",
															value: "upgrade",
														},
														{
															label: "Advanced",
															value: "advanced",
														},
													],
													onChange:
														props.handleUserTypeChange,
													value: props.parameters
														.userType,
												}}
											/>
											<TableCell />
											<TableCell />
											<TableCell
												selectParameters={{
													options: [
														{
															label: "ALL",
															value: "",
														},
														{
															label: "Ios",
															value: "ios",
														},
														{
															label: "Android",
															value: "Android",
														},
													],
													onChange:
														props.handlePlatformChange,
													value: props.parameters
														.platform,
												}}
											/>
											<TableCell />
										</tr>
										{props.loading && (
											<tr>
												<td colSpan={8}>
													<Spinner />
												</td>
											</tr>
										)}
										{!props.loading &&
											!props.users.length && (
												<tr>
													<td colSpan={8}>
														<EmptyDataContainer />
													</td>
												</tr>
											)}
										{!props.loading &&
											props.users.map((user, i) => (
												<tr key={i}>
													<td className="Cell">
														{i +
															1 +
															(props.parameters
																.page -
																1) *
																props.parameters
																	.limit}
													</td>
													<td className="Cell">
														{user.email}
													</td>
													<td className="Cell">
														{user.fullName}
													</td>
													<td className="Cell">
														{user.currentPlan
															? user.currentPlan.substr(
																	26
															  )
															: "Free"}
													</td>
													<td className="Cell">
														{formatDate(
															user.createdAt
														)}
													</td>
													<td className="Cell">
														{formatDate(
															user.drivingDate
																.date,
															"YYYY-MM-DD"
														)}{" "}
														{user.drivingDate.time}
													</td>
													<td className="Cell">
														{user.platform}
													</td>
													<td className="Cell">
														<Button
															className="mr-1"
															btnType="primary"
															onClick={() =>
																props.onUserAction(
																	"view",
																	user
																)
															}
														>
															view
														</Button>
													</td>
												</tr>
											))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	if (props.userTypeFilter === "paidUsers") {
		return (
			<div className="row stats-wrapper">
				<div className="col-lg-12">
					<div className="ibox float-e-margins">
						<div className="ibox-content">
							<div className="table-responsive">
								<table className="table table-bordered table-hover dataTables-example dataTable">
									<thead>
										<tr>
											<TableCell
												header
												label="#"
												width="5%"
											/>
											<TableCell
												sortParameters={{
													order: props.parameters
														.order,
													orderBy:
														props.parameters
															.orderBy,
													name: "email",
													handleSortChange:
														props.handleSortChange,
												}}
												label="Email"
												width="20%"
											/>
											<TableCell
												sortParameters={{
													order: props.parameters
														.order,
													orderBy:
														props.parameters
															.orderBy,
													name: "name",
													handleSortChange:
														props.handleSortChange,
												}}
												label="Name"
												width="20%"
											/>
											<TableCell
												sortParameters={{
													order: props.parameters
														.order,
													orderBy:
														props.parameters
															.orderBy,
													name: "userType",
													handleSortChange:
														props.handleSortChange,
												}}
												label="User Type"
												width="20%"
												style={{ minWidth: "90px" }}
											/>
											<TableCell
												sortParameters={{
													order: props.parameters
														.order,
													orderBy:
														props.parameters
															.orderBy,
													name: "createdAt",
													handleSortChange:
														props.handleSortChange,
												}}
												label="Created At"
												width="10%"
											/>
											<TableCell
												sortParameters={{
													order: props.parameters
														.order,
													orderBy:
														props.parameters
															.orderBy,
													name: "paymentDate",
													handleSortChange:
														props.handleSortChange,
												}}
												label="Purchase Date"
												width="10%"
											/>
											<TableCell
												header
												label="Platform"
												width="10%"
											/>
											<TableCell
												header
												label="Action"
												width="10%"
												style={{ minWidth: "160px" }}
											/>
										</tr>
									</thead>
									<tbody className="stats-list">
										<tr>
											<TableCell />
											<TableCell
												searchParameters={{
													onChange:
														props.handleEmailChange,
													value: props.parameters
														.email,
													placeholder: "search email",
												}}
											/>
											<TableCell
												searchParameters={{
													onChange:
														props.handleNameChange,
													value: props.parameters
														.name,
													placeholder: "search name",
												}}
											/>
											<TableCell
												selectParameters={{
													options: [
														{
															label: "ALL",
															value: "",
														},
														{
															label: "Basic",
															value: "basic",
														},
														{
															label: "Upgrade",
															value: "upgrade",
														},
														{
															label: "Advanced",
															value: "advanced",
														},
													],
													onChange:
														props.handleUserTypeChange,
													value: props.parameters
														.userType,
												}}
											/>
											<TableCell />
											<TableCell />
											<TableCell
												selectParameters={{
													options: [
														{
															label: "ALL",
															value: "",
														},
														{
															label: "Ios",
															value: "ios",
														},
														{
															label: "Android",
															value: "Android",
														},
													],
													onChange:
														props.handlePlatformChange,
													value: props.parameters
														.platform,
												}}
											/>
											<TableCell />
										</tr>
										{props.loading && (
											<tr>
												<td colSpan={8}>
													<Spinner />
												</td>
											</tr>
										)}
										{!props.loading &&
											!props.users.length && (
												<tr>
													<td colSpan={8}>
														<EmptyDataContainer />
													</td>
												</tr>
											)}
										{!props.loading &&
											props.users.map((user, i) => (
												<tr key={i}>
													<td className="Cell">
														{i +
															1 +
															(props.parameters
																.page -
																1) *
																props.parameters
																	.limit}
													</td>
													<td className="Cell">
														{user.email}
													</td>
													<td className="Cell">
														{user.fullName}
													</td>
													<td className="Cell">
														{user.currentPlan
															? user.currentPlan.substr(
																	26
															  )
															: "Free"}
													</td>
													<td className="Cell">
														{formatDate(
															user.createdAt
														)}
													</td>
													<td className="Cell">
														{user.paymentDate
															? formatDate(
																	user.paymentDate
															  )
															: ""}
													</td>
													<td className="Cell">
														{user.platform}
													</td>
													<td className="Cell">
														<Button
															className="mr-1"
															btnType="primary"
															onClick={() =>
																props.onUserAction(
																	"view",
																	user
																)
															}
														>
															view
														</Button>
													</td>
												</tr>
											))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="row stats-wrapper">
			<div className="col-lg-12">
				<div className="ibox float-e-margins">
					<div className="ibox-content">
						<div className="table-responsive">
							<table className="table table-bordered table-hover dataTables-example dataTable">
								<thead>
									<tr>
										<TableCell
											header
											label="#"
											width="5%"
										/>
										<TableCell
											sortParameters={{
												order: props.parameters.order,
												orderBy:
													props.parameters.orderBy,
												name: "email",
												handleSortChange:
													props.handleSortChange,
											}}
											label="Email"
											width="15%"
										/>
										<TableCell
											sortParameters={{
												order: props.parameters.order,
												orderBy:
													props.parameters.orderBy,
												name: "fullName",
												handleSortChange:
													props.handleSortChange,
											}}
											label="Name"
											width="10%"
										/>
										<TableCell
											sortParameters={{
												order: props.parameters.order,
												orderBy:
													props.parameters.orderBy,
												name: "userType",
												handleSortChange:
													props.handleSortChange,
											}}
											label="User Type"
											width="10%"
											style={{ minWidth: "90px" }}
										/>

										<TableCell
											sortParameters={{
												order: props.parameters.order,
												orderBy:
													props.parameters.orderBy,
												name: "createdAt",
												handleSortChange:
													props.handleSortChange,
											}}
											label="Created At"
											width="10%"
										/>
										<TableCell
											header
											label="Purchase Date"
											width="10%"
										/>
										<TableCell
											header
											label="Completed Test Routes"
											width="5%"
										/>
										<TableCell
											header
											label="Completed Actual Routes"
											width="5%"
										/>
										<TableCell
											header
											label="Cancelled Test Routes"
											width="5%"
										/>
										<TableCell
											header
											label="Cancelled Actual Routes"
											width="5%"
										/>
										<TableCell
											header
											label="Platform"
											width="5%"
										/>
										<TableCell
											header
											label="Action"
											width="15%"
											style={{ minWidth: "160px" }}
										/>
									</tr>
								</thead>
								<tbody className="stats-list">
									<tr>
										<TableCell />
										<TableCell
											searchParameters={{
												onChange:
													props.handleEmailChange,
												value: props.parameters.email,
												placeholder: "search email",
											}}
										/>
										<TableCell
											searchParameters={{
												onChange:
													props.handleNameChange,
												value: props.parameters.name,
												placeholder: "search name",
											}}
										/>
										<TableCell
											selectParameters={{
												options: [
													{ label: "ALL", value: "" },
													{
														label: "Free",
														value: "free",
													},
													{
														label: "Basic",
														value: "basic",
													},
													{
														label: "Upgrade",
														value: "upgrade",
													},
													{
														label: "Advanced",
														value: "advanced",
													},
													{
														label: "Premium",
														value: "premium",
													},
												],
												onChange:
													props.handleUserTypeChange,
												value: props.parameters
													.userType,
											}}
										/>
										<TableCell />
										<TableCell />
										<TableCell />
										<TableCell />
										<TableCell />
										<TableCell />
										<TableCell
											selectParameters={{
												options: [
													{ label: "ALL", value: "" },
													{
														label: "Ios",
														value: "ios",
													},
													{
														label: "Android",
														value: "Android",
													},
												],
												onChange:
													props.handlePlatformChange,
												value: props.parameters
													.platform,
											}}
										/>
										<TableCell />
									</tr>
									{props.loading && (
										<tr>
											<td colSpan={12}>
												<Spinner />
											</td>
										</tr>
									)}
									{!props.loading && !props.users.length && (
										<tr>
											<td colSpan={12}>
												<EmptyDataContainer />
											</td>
										</tr>
									)}
									{!props.loading &&
										props.users.map((user, i) => (
											<tr key={i}>
												<td className="Cell">
													{i +
														1 +
														(props.parameters.page -
															1) *
															props.parameters
																.limit}
												</td>
												<td className="Cell">
													{user.email}
												</td>
												<td className="Cell">
													{user.fullName}
												</td>
												<td className="Cell">
													{user.currentPlan
														? user.currentPlan.substr(
																26
														  )
														: "Free"}
												</td>
												<td className="Cell">
													{formatDate(user.createdAt)}
												</td>
												<td className="Cell">
													{user.paymentDate
														? formatDate(
																user.paymentDate
														  )
														: ""}
												</td>
												<td className="Cell">
													{
														user.completedTestTraversals
													}
												</td>
												<td className="Cell">
													{
														user.completedActualTraversals
													}
												</td>
												<td className="Cell">
													{
														user.cancelledTestTraversals
													}
												</td>
												<td className="Cell">
													{
														user.cancelledActualTraversals
													}
												</td>
												<td className="Cell">
													{user.platform}
												</td>
												{/* <td className='Cell'>{user.contactNumber || ''}</td> */}
												<td className="Cell">
													<Button
														className="mr-1"
														btnType="primary"
														onClick={() =>
															props.onUserAction(
																"view",
																user
															)
														}
													>
														view
													</Button>
													<Button
														className="Action-btn mr-1"
														btnType="default"
														onClick={() =>
															props.onUserAction(
																"edit",
																user
															)
														}
													>
														<i className="fa fa-pencil" />
													</Button>
													<Button
														className="Action-btn"
														btnType="danger"
														onClick={() =>
															props.onUserAction(
																"delete",
																user
															)
														}
													>
														<i className="fa fa-trash" />
													</Button>
												</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default UsersList;
