
const validationErrorMessages = {
	required: (field: string) => `${field} is required!`,
	trim: (field: string) => `${field} must not contain white spaces at starting or end position!`,
}

const confirmMessages = {
	confirmLogout: () => 'Are you sure you want to sign out of this application?',
	confirmDelete: (field: string) => `Are you sure you want to delete ${field}?`,
	confirmAction: (field: string, action: string) => `Are you sure you want to ${action} ${field}?`,
}

export {
	validationErrorMessages,
	confirmMessages
}
