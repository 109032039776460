import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { State } from "shared/interface";
import PracticeCenterUserReportContainer from "features/practice-center-report/practice-center-user-report/practice-center-user-report.container";

interface MapStateProps extends RouteComponentProps<{}> {
	proUserStatus: boolean;
}

const PracticeCenterReportWrapper = (props: any) => {
	return (
		<div>
			<PracticeCenterUserReportContainer {...props} />
		</div>
	);
};

const mapStateToProps = (state: State) => ({
	proUserStatus: state.common.proUserStatus,
});

export default connect(mapStateToProps)(PracticeCenterReportWrapper);
