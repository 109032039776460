import React from 'react';
import { ErrorMessage, Formik } from 'formik';
import CustomModal from '../../shared/components/modal/modal';
import { Input, FieldConfiguration } from '../../shared/components/form/input';
import * as schema from '../../shared/constants/validation-schema';
import Button from '../../shared/components/form/button';
import { NotificationFormParams } from './config.interface';
import FieldErrorMessage from '../../shared/components/form/error';

interface Props {
    show: boolean;
    loading: boolean;
    initialValues: NotificationFormParams;
    onCancel: () => void;
    onSubmit: (values: NotificationFormParams) => void;
}

const userTypeValues = [
	{ key: 'Android', value: 'android' },
	{ key: 'IOS', value: 'ios' },
];

const userTypeValueKeys = userTypeValues.map(v => v.value)

const NotificationForm = (props: Props) => {    
    return (
        <CustomModal show={props.show} handleClose={props.onCancel}>
            <div className='form'>
                <h3 className='heading text-center'>Send Notification</h3>
                <Formik
                    initialValues={props.initialValues}
                    validateOnBlur
                    validateOnChange={false}
                    onSubmit={props.onSubmit}
                    validationSchema={schema.notificationFormValidationSchema} >

                    {({ handleSubmit, setFieldValue, values, errors }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <fieldset>
                                    <div className='form-group'>
                                        <label className='text-capitalize input-label-wrapper'>User Type</label>
                                        <select
                                            className='form-control'
                                            value={values.device}
                                            onChange={(e) => {
                                                const val: string = e.target.value;
                                                if (userTypeValueKeys.includes(val)) {
                                                    setFieldValue('userType', val);
                                                }
                                            }}
                                        >
                                            {userTypeValues.map((val) => <option value={val.value}>{val.key}</option>)}
                                        </select>
                                        <ErrorMessage name='userType' component={FieldErrorMessage} />
                                    </div>

                                    {fieldConfig.map((config, index) => (
                                        <div key={index} className='form-group'>
                                            <Input
                                                setFieldValue={setFieldValue}
                                                config={config}
                                                placeholder={config.placeHolder || config.label}
                                                type={config.type}
                                                name={config.name} />
                                        </div>
                                    ))}

                                    <div className='mb-3 mt-3 d-flex align-items-center justify-content-center'>
                                        <Button
                                            className='width-100px block m-b'
                                            loading={props.loading}
                                            type='submit'
                                            btnType='primary'>
                                            Submit
                                        </Button>
                                    </div>

                                </fieldset>
                            </form>
                        )
                    }}
                </Formik>
			</div>
        </CustomModal>
    );
}

const fieldConfig: FieldConfiguration[] = [
	{ type: 'text', label: 'Topic', name: 'topic' },
	{ type: 'text', label: 'Title', name: 'title' },
	{ type: 'text', label: 'Message', name: 'message' },
	{ type: 'text', label: 'Action', name: 'action' },
];

export default NotificationForm;
