import React from 'react';

import Spinner from 'shared/components/spinner/spinner';
import EmptyDataContainer from 'shared/components/emptyDataContainer/emptyDataContainer';
import Button from 'shared/components/form/button';
import { formatDate } from 'shared/util/utility';
import TableCell from 'shared/components/table/tableCell';

import { CenterSchema } from './centers.interface';

interface Props {
	centers: CenterSchema[];
	loading: boolean;
	search: {
		name: string;
	};
	onCenterAction: (action: string, center: CenterSchema) => void;
	onNameChange: React.ChangeEventHandler<HTMLInputElement>;
}

const ActualCenterList = (props: Props) => {
	return (
		<div className='row stats-wrapper'>
			<div className='col-lg-12'>
				<div className='ibox float-e-margins'>
					<div className='ibox-content'>
					<div className='table-responsive'>
						<table className='table table-bordered table-hover dataTables-example dataTable'>
							<thead>
								<tr>
									<th className='text-capitalize cursor-pointer'>#</th>
									<th className='text-capitalize cursor-pointer' style={{ width: '20%', minWidth: '150px' }}>Name</th>
									<th className='text-capitalize cursor-pointer' style={{ width: '20%', minWidth: '150px' }}>Address</th>
									<th className='text-capitalize cursor-pointer'>Coordinates</th>
									<th className='text-capitalize cursor-pointer'>Created At</th>
									<th className='text-capitalize cursor-pointer' style={{ minWidth: '196px' }}>Action</th>
								</tr>
							</thead>
							<tbody className='stats-list'>
								<tr>
									<TableCell />
									<TableCell
										searchParameters={{
											onChange: props.onNameChange,
											value: props.search.name,
											placeholder: 'search Name'
										}}
									/>
									<TableCell />
									<TableCell />
									<TableCell />
									<TableCell />
								</tr>

								{props.loading && <tr><td colSpan={7}><Spinner /></td></tr>}
								{!props.loading && !props.centers.length && <tr><td colSpan={7}><EmptyDataContainer /></td></tr>}
								{!props.loading && props.centers.map((center, i) => (
									<tr key={i}>
										<td className='Cell'>{i + 1}</td>
										<td className='Cell'>{center.name}</td>
										<td className='Cell'>{center.address ? center.address.address : ''}</td>
										<td className='Cell'>
											{center.address ? `(${center.address.latitude}, ${center.address.longitude})` : ''}
										</td>
										<td className='Cell'>{formatDate(center.createdAt)}</td>
										<td className='Cell'>
											<Button
												className='action-btn'
												btnType='info'
												onClick={() => props.onCenterAction('edit', center)}
											>
												<i className='fa fa-pencil' />
											</Button>
                                            {!center.isAddedAsTest &&
                                                <Button
                                                    className='mr-2 btn-success'
                                                    btnType=''
                                                    onClick={() => props.onCenterAction('makeTest', center)}
                                                >
                                                    set as test
                                                </Button>
                                            }
											<Button
												className='action-btn'
												btnType='danger'
												onClick={() => props.onCenterAction('delete', center)}
											>
												<i className='fa fa-trash' />
											</Button>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default ActualCenterList;
