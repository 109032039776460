import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import queryString from "query-string";

import {
	State,
	Action,
	ModalStatusProps,
	ActualTestUsersState,
} from "shared/interface";
import {
	createLoadingSelector,
	createAction,
	debounce,
} from "shared/util/utility";
import { modalStatusProps } from "shared/constants/constants";
import * as UserActions from "./actualTestUsers.action";
import ActualTestUsersList from "./actualTestUsersList";
import {
	AppUserTest,
	UserFetchParameters,
	OptionalUserFetchParameters,
} from "./actualTestUsers.interface";
import { Link, RouteComponentProps } from "react-router-dom";
import actionTypes from "store/action-types";
import PaginationComponent from "shared/components/table/pagination";

interface MapStateProps extends ActualTestUsersState {
	open: boolean;
	action: string;
	loading: boolean;
	loadingAction: boolean;
}

interface DispatchProps extends ModalStatusProps {
	fetchUsers: (data: UserFetchParameters) => void;
	setMessage: (type: string, message: string) => void;
}

const Users = (props: MapStateProps & DispatchProps & RouteComponentProps) => {
	const [user, setUser] = useState<AppUserTest>({} as any);
	const [search, setSearch] = useState<any>({
		name: "",
		email: "",
		centerName: "",
	});

	const onUserAction = (action: string, user?: AppUserTest) => {
		if (user) {
			setUser(user);
		}
		props.openModal(action);
	};
	const onCancel = () => {
		setUser({} as any);
		props.closeModal();
	};
	const getQuery = (
		data: OptionalUserFetchParameters
	): UserFetchParameters => ({
		name: data.name === undefined ? props.userParameters.name : data.name,
		email:
			data.email === undefined ? props.userParameters.email : data.email,
		centerName:
			data.centerName === undefined
				? props.userParameters.centerName
				: data.centerName,
		status:
			data.status === undefined
				? props.userParameters.status
				: data.status,
		userType:
			data.userType === undefined
				? props.userParameters.userType
				: data.userType,
		limit:
			data.limit === undefined ? props.userParameters.limit : data.limit,
		page: data.page === undefined ? props.userParameters.page : data.page,
		order:
			data.order === undefined ? props.userParameters.order : data.order,
		orderBy:
			data.orderBy === undefined
				? props.userParameters.orderBy
				: data.orderBy,
		platform:
			data.platform === undefined
				? props.userParameters.platform
				: data.platform,
	});
	const searchName = debounce((name: string) => props.fetchUsers(getQuery({ name })));
	const searchEmail = debounce((email: string) =>
	props.fetchUsers(getQuery({ email })));
	const searchCenterName = (centerName: string) =>
		props.fetchUsers(getQuery({ centerName }));
	const handleSearchName = (e: React.ChangeEvent<HTMLInputElement>) => {
		const name = e.target.value;
		if (props.loading) {
			return;
		}
		setSearch({
			...search,
			name,
		});
		searchName(name);
	};
	const handleSearchEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
		const email = e.target.value;
		if (props.loading) {
			return;
		}
		setSearch({
			...search,
			email,
		});
		searchEmail(email);
	};
	const handleCenterSearchName = (e: React.ChangeEvent<HTMLInputElement>) => {
		const centerName = e.target.value;
		if (props.loading) {
			return;
		}
		// this.setState({ search: { ...this.state.search, centerName } });
		setSearch({
			...search,
			centerName,
		});
		searchCenterName(centerName);
	};
	const handleFilterUserType = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const userType = e.target.value;
		if (props.loading) {
			return;
		}
		props.fetchUsers(getQuery({ userType, page: 1 }));
	};
	const handlePlatformChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const platform = e.target.value;
		if (props.loading) {
			return;
		}
		props.fetchUsers(getQuery({ platform, page: 1 }));
	};
	const handleFilterStatus = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const status = e.target.value;
		if (props.loading) {
			return;
		}
		props.fetchUsers(getQuery({ status, page: 1 }));
	};
	const handlePageChange = (page: number) =>
		props.fetchUsers(getQuery({ page }));
	const handlePageSelectionChange = (limit: number) =>
		props.fetchUsers(getQuery({ page: 1, limit }));
	const handleSortChange = (order: "asc" | "desc", orderBy: string) =>
		props.fetchUsers(getQuery({ order, orderBy }));
	const handleUserHeadingClick = () => {
		if (props.action === "view") {
			props.closeModal();
		}
	};

	useEffect(() => {
		const params: any = queryString.parse(props.location.search);
		if (params.type) {
			// setState({ activeTab: params.type });
			props.fetchUsers(getQuery({ userType: params.type }));
		} else {
			props.fetchUsers(getQuery({}));
		}

		return () => {};
	}, []);

	return (
		<>
			<div>
				<div className="justify-content-between d-flex page-heading">
					<div className="heading">
						<Link
							to="/actual-test-users"
							onClick={handleUserHeadingClick}
						>
							<p>Actual Test Users</p>
						</Link>
					</div>
				</div>
				{props.action !== "view" && (
					<>
						<PaginationComponent
							{...props.userPagination}
							active={props.userPagination.page}
							handlePageChange={handlePageChange}
							handlePageSelectionChange={
								handlePageSelectionChange
							}
						/>
						<ActualTestUsersList
							loading={props.loading}
							users={props.users}
							onUserAction={onUserAction}
							parameters={{
								name: search.name,
								email: search.email,
								userType: props.userParameters.userType,
								centerName: search.centerName,
								status: props.userParameters.status,
								page: props.userParameters.page,
								limit: props.userParameters.limit,
								order: props.userParameters.order,
								orderBy: props.userParameters.orderBy,
								platform: props.userParameters.platform,
							}}
							handleNameChange={handleSearchName}
							handleEmailChange={handleSearchEmail}
							handleUserTypeChange={handleFilterUserType}
							handlePlatformChange={handlePlatformChange}
							handleStatusChange={handleFilterStatus}
							handleSortChange={handleSortChange}
							handleCenterNameChange={handleCenterSearchName}
						/>
					</>
				)}
			</div>
		</>
	);
};


const loadingSelector = createLoadingSelector([
	"FETCH_USERS",
	"FETCH_ACTUAL_TEST_USERS",
]);
const loadingActionSelector = createLoadingSelector(["ENABLE_USER"]);

const mapStateToProps = (state: State): MapStateProps => ({
	...state.reportActualTestUsers,
	...state.common.modalStatus,
	loading: loadingSelector(state),
	loadingAction: loadingActionSelector(state),
});

const mapDispatchToProps = (
	dispatch: ThunkDispatch<{}, {}, Action>
): DispatchProps => ({
	...modalStatusProps(dispatch),
	fetchUsers: (data) => dispatch(UserActions.fetchUsers(data)),
	setMessage: (type, message) =>
		dispatch(createAction(actionTypes.SET_MESSAGE, { type, message })),
});

export default connect<MapStateProps, DispatchProps, {}, State>(
	mapStateToProps,
	mapDispatchToProps
)(Users);
